<template>
  <CustomAvatar :readyToDisplay="waWebPhone != null" :dataIdentifier="objectId" :displaySmall="displaySmall"
    :imageUrl="waWebPhone?.data?.profile_picture_url" :showDetails="showDetails" :showInCard="showInCard"
    :showImage="showImage" :actionMenuOptions="menuOptions"
    :class="{ 'wa-web-phone-not-connected': waWebPhone?.state == 'NOT_CONNECTED','wa-web-phone-connected': waWebPhone?.state == 'CONNECTED' }"
    @actionMenuClick="clickMenuAction" @itemClick="navigateToInstaUser" @refreshClick="getPhone(true)">
    <template v-slot:defaultImage>
      <i class="fa-solid fa-phone"></i>
    </template>
    <template v-slot:mainData>
      {{ waWebPhone.name }}
    </template>
    <template v-slot:dataDetails>
      {{ waWebPhone?.data?.info?.pushname }}
      <span>{{ showPhoneState }}</span>
    </template>
    <template v-slot:additionalButtons> </template>
    <template v-slot:popupData>
      <div v-html="getObjectDefaultDisplay()"></div>
    </template>
  </CustomAvatar>
</template>

<script>
import { displayObjectData } from "@/helpers/common";
export default {
  name: "WaContactAvatar",
  props: {
    organisationId: { type: String },
    id: { type: String },
    number: { type: String },
    navigateOnClick: { type: Boolean,default: () => false },
    showInCard: { type: Boolean,default: () => false },
    showDetails: { type: Boolean,default: () => false },
    displaySmall: { type: Boolean,default: () => false },
    showImage: { type: Boolean,default: () => true },
  },
  data() {
    return {
      objectId: undefined,
      waWebPhone: undefined,
      menuOptions: [],
    };
  },
  computed: {
    showPhoneState() {
      return this.waWebPhone?.state != null
        ? this.$i18n?.te(`model.waWebPhoneStatus-${this.waWebPhone?.state}`)
          ? this.$i18n?.t(`model.waWebPhoneStatus-${this.waWebPhone?.state}`)
          : this.waWebPhone?.state
        : "";
    },
  },
  watch: {
    id() {
      this.getPhone();
    },
    number() {
      this.getPhone();
    },
    waWebPhone() {
      this.objectId = this.waWebPhone?._id;
    },
    "$store.state.lastUpdateNotification"(val) {
      if (
        val &&
        val.objectType.match(/^WAWEBPHONE/) &&
        val.data &&
        Array.isArray(val.data) &&
        val.data.find((x) => x._id == this.objectId)
      ) {
        this.getPhone();
      }
    },
  },
  mounted() {
    this.getPhone();
  },
  methods: {
    clickMenuAction() { },
    navigateToInstaUser() {
      if (this.navigateOnClick == false) {
        return;
      }
      const query = {};
      if (this.organisationId) {
        query.organisationId = this.organisationId;
      }

      this.$router.push({
        name: "waWebPhoneDetails",
        params: { id: this.objectId },
        query,
      });
    },
    getPhone(force = false) {
      if (this.id) {
        this.$store
          .dispatch("waWebPhone/getById",{
            id: this.id,
            organisationId: this.organisationId,
            force,
            mergeRequest: true
          })
          .then((d) => {
            this.waWebPhone = d;
          });
      } else if (this.number) {
        this.$store
          .dispatch("waWebPhone/getAll",{
            organisationId: this.organisationId,
            options: {
              filter: { number: this.number },
              force,
            },
          })
          .then((phones) => {
            if (phones.data?.length > 0) {
              this.waWebPhone = phones.data[0];
            } else {
              this.waWebPhone = undefined;
            }
          });
      }
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.waWebPhone?.data ?? {});
    },
  },
};
</script>

<style lang="scss" scoped>
.wa-web-phone-not-connected {
  &> :deep(.avatar-img-container) {
    margin: .2rem;
    box-shadow: 0 0 0.1rem 0.1rem rgba(var(--vs-danger), 0.5) !important
  }
}

.wa-web-phone-connected {
  &> :deep(.avatar-img-container) {
    margin: .2rem;
    box-shadow: 0 0 0.1rem 0.1rem rgba(var(--vs-success), 0.1) !important
  }
}
</style>