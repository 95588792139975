<template>
  <div v-if="templateOptions">
    <select :value="selectedTemplateId" :disabled="disabled" @input="changeTemplate($event.target.value)">
      <option v-for="option in templateOptions" :key="option.val" :value="option.val"> {{ option.label }}
      </option>
    </select>
    <div v-if="selectedTemplate">
      <FbWaBaTemplateDisplay :templateData="selectedTemplate?.data" :showTemplateField="showTemplateField"
        :templateValues="templateParams" @paramsUpdated="(val) => updateParams(val)"></FbWaBaTemplateDisplay>
    </div>
  </div>
</template>

<script>

import { parseIntNumber } from "../../helpers/common";
import FbWaBaTemplateDisplay from "./FbWaBaTemplateDisplay.vue";

export default {
  name: "FbWaBaTemplateSelectorCmp",
  components: {
    FbWaBaTemplateDisplay
  },
  props: {
    fbWaBAId: { type: String,default: () => undefined },
    showOnlyNotRemoteDelete: { type: Boolean,default: () => false },

    showTemplateField: { type: Boolean,default: () => false },
    modelValue: { type: Object,default: () => undefined },
    disabled: { type: Boolean,default: () => false },
    params: { type: Object,default: () => undefined },
  },
  emits: ['update:modelValue'],
  watch: {
    fbWaBAId() {
      this.updateOptions();
    },
    async modelValue(val) {
      if (val != null) {
        if (typeof val == "object") {
          if (this.templateDataList == null) {
            await this.updateOptions();
          }
          this.selectedTemplateId = this.templateDataList?.find(x => x.data.name == val.name && x.data.language == val.language?.code)?._id;
          this.setSelectedTemplate();
        }
      }
    },
  },
  data() {
    return {
      templateDataList: undefined,
      selectedTemplate: undefined,
      selectedTemplateId: undefined,
      templateOptions: undefined,
      templateParams: undefined,
    };
  },
  mounted() {
    this.updateOptions().then(() => {
      this.selectedTemplateId = this.templateDataList?.find(x => x.data.name == this.modelValue?.name && x.data.language == this.modelValue?.language?.code)?._id;
      this.setSelectedTemplate();
    });
  },
  methods: {
    setSelectedTemplate() {
      if (this.selectedTemplateId != null) {
        this.selectedTemplate = this.templateDataList?.find(x => x._id == this.selectedTemplateId);
      } else {
        this.selectedTemplate = undefined;
      }
    },
    changeTemplate(val) {
      if (val != this.selectedTemplateId) {
        this.templateParams = undefined;
      }
      this.selectedTemplateId = val;
      this.setSelectedTemplate();
      this.emitValue();
    },
    updateParams(val) {
      this.templateParams = val;
      this.emitValue();
    },
    emitValue() {
      const data = {
        name: this.selectedTemplate?.data?.name,
        language: {
          code: this.selectedTemplate?.data?.language
        }
      };

      if (this.templateParams != null && this.templateParams.body != null) {
        let maxNum = 0;
        const validatedParams = Object.fromEntries(
          Object.entries(this.templateParams.body)
            .map(([key,val]) => {
              if (val == null) {
                val = "";
              }
              key = parseIntNumber(key);
              if(maxNum < key){
                maxNum = key;
              }
              return [key,val];
            }).filter(([key,val]) => key != null)
        );

        if (Object.keys(validatedParams).length > 0) {
          const orderedParams = Array.from({ length: maxNum },(_,i) => validatedParams[i + 1] ?? "")
          data.components = [{
            type: "body",
            parameters: orderedParams.map(x => {
              return {
                type: "text",
                text: x
              };
            })
          }]
        }
      }

      this.$emit('update:modelValue',data);
      return;
    },
    async updateOptions() {
      if (this.fbWaBAId != null) {
        const reqFilter = {};
        if (this.showOnlyNotRemoteDelete == true) {
          reqFilter.isRemoteDeleted = false;
        }

        return this.$store.dispatch("fbWaMessageTemplate/getAll",{
          fbWaBAId: this.fbWaBAId,
          options: { filter: reqFilter }
        }).then((arr) => {
          if (arr.data != null && Array.isArray(arr.data)) {
            this.templateDataList = arr.data.filter(x => x.data?.name != null);
            this.templateOptions = this.templateDataList?.map(x => {
              return { label: x.data.name,val: x._id };
            })
          } else {
            this.templateOptions = [];
          }
        });
      } else {
        this.templateOptions = [];
      }
    }
  },
};
</script>
<style lang="scss" scoped></style>