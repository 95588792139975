<template>
  <div v-if="templateData != null" :class="{ 'display-inline': displayInline }" class="template-display-holder">
    <div class="template-status-extras" v-if="displayInline == false">
      <div class="template-status-container">
        <div v-if="this.templateStatus" class="template-status">
          {{ this.templateStatus }}
        </div>
        <div v-if="this.templateRejection != 'NONE'" class="rejection-reason">
          {{ this.templateRejection }}
        </div>
      </div>
      <div class="template-details-container">
        <div class="template-category" v-if="templateCategory != null">
          {{ templateCategory }}
        </div>
        <div class="template-language-container" v-if="templateLanguage != null">
          {{ templateLanguage }}
        </div>
      </div>
    </div>
    <div class="template-container-holder">
      <div class="template-container-content">
        <div class="template-container-header" v-if="templateHeader != null">
          <div class="template-container-header-text"
            v-if="templateHeader.format == 'TEXT' && templateHeader.text != null">
            {{ templateHeader.text }}
          </div>
        </div>

        <div class="template-container-body" v-if="templateBody != null">
          <div class="template-container-body-text" v-if="templateBody.text != null">
            <div v-if="templateBody.textHtml" v-html="templateBody.textHtml"></div>
            <div v-else>{{ templateBody.text }}</div>
          </div>
        </div>

        <div class="template-container-footer" v-if="templateFooter != null">
          <div class="template-container-footer-text" v-if="templateFooter.text != null">
            {{ templateFooter.text }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="showTemplateField">
      <CustomForm :fields="fields" :initialValue="formValue" @formSubmit="(val,e) => updateParamValue(val,e)"
        class="params-form">
      </CustomForm>
    </div>
  </div>
</template>

<script>
import { generalScriptTagRegex } from '../../helpers/common';

export default {
  name: "FbWaBaTemplateDisplayCmp",
  components: {},
  props: {
    templateData: { type: Object,default: () => undefined },
    templateValues: { type: Object,default: () => undefined },
    displayInline: { type: Boolean,default: () => false },
    showTemplateField: { type: Boolean,default: () => false },
  },
  watch: {
    templateData() {
      this.parseComponents();
    },
  },
  emits: ["paramsUpdated"],
  data() {
    return {
      fields: [],
      formValue: {},
      templateParamValues: undefined,
      templateExampleValues: undefined,
      templateStatus: undefined,
      templateRejection: undefined,
      templateCategory: undefined,
      templateHeader: undefined,
      templateBody: undefined,
      templateFooter: undefined,
      templateLanguage: undefined,
    };
  },
  mounted() {
    this.parseComponents();
  },
  methods: {
    updateParamValue(data) {
      if (this.templateParamValues == null) {
        this.templateParamValues = {}
      }
      Object.entries(data).forEach(([key,value]) => {
        if (this.templateParamValues[key] == null) {
          this.templateParamValues[key] = {}
        }
        if (value != null && typeof value == "object") {
          Object.entries(value).forEach(([fkey,fvalue]) => {
            this.templateParamValues[key][fkey] = fvalue;
          });
        }
      });

      this.formValue = data;
      this.updateParameteredTextDisplay();
      this.$emit("paramsUpdated",this.formValue);
    },
    updateFields() {
      const tmp = Object.entries(this.templateExampleValues)
        .map(([key,val]) => {
          return {
            key,
            label: `model.template-${key}`,
            type: 'input-group',
            fields: Object.entries(val).map(([fKey,fValue]) => {
              return {
                key: fKey,
                label: fKey,
                type: "text",
                placeholder: fValue
              };
            })
          }
        });
      this.fields = tmp;
    },
    updateIntialValue() {
      const tmp = Object.fromEntries(
        Object.entries(this.templateParamValues)
          .map(([key,val]) => [key,
            Object.fromEntries(
              Object.entries(val).map(([fKey,fValue]) => {
                return [fKey,fValue];
              }))
          ]));
      this.formValue = tmp;
    },
    getTemplateValueReplace(key,place = 'body') {
      if (
        this.templateParamValues != null &&
        this.templateParamValues[place] != null &&
        typeof this.templateParamValues[place] == "object" &&
        key != null &&
        this.templateParamValues[place][key] != null
      ) {

        const exampleValue = (this.templateExampleValues != null &&
          this.templateExampleValues[place] != null &&
          this.templateExampleValues[place][key] != null) ? this.templateExampleValues[place][key] : undefined;

        const isTemp = this.templateParamValues[place][key] == null || this.templateParamValues[place][key] == "";

        return {
          value: isTemp ? exampleValue : this.templateParamValues[place][key],
          isTemp
        };
      }
      return { value: key,isTemp: true };
    },
    updateParameteredTextDisplay() {
      if (this.templateBody != null) {
        if (this.templateBody.text != null) {
          this.templateBody.text = this.templateBody.text.replace(generalScriptTagRegex,'');
          if (/\{\{\s*(\S+)\s*\}\}/gi.test(this.templateBody.text)) {
            const matchs = Array.from(this.templateBody.text.matchAll(/\{\{\s*(\S+)\s*\}\}/gi))
            this.templateBody.textHtml = this.templateBody.text;
            if (matchs != null) {
              for (let index = 0;index < matchs.length;index++) {
                const match = matchs[index];
                if (match != null && match[0] != null) {
                  const newValue = this.getTemplateValueReplace(match[1]);
                  this.templateBody.textHtml = this.templateBody.textHtml.replace(match[0],`<span class="templated-value ${newValue.isTemp ? 'temp-data' : ''}">${newValue.value}</span>`)
                }
              }
            }
          }
        }
      }
    },
    parseComponents() {
      if (this.templateData != null) {
        if (Array.isArray(this.templateData.components)) {
          this.templateHeader = this.templateData.components.find(x => x.type == "HEADER");
          this.templateBody = this.templateData.components.find(x => x.type == "BODY");
          this.templateFooter = this.templateData.components.find(x => x.type == "FOOTER");
        } else {
          this.templateHeader = undefined;
          this.templateBody = undefined;
          this.templateFooter = undefined;
        }
        this.templateLanguage = this.templateData.language;
        this.templateStatus = this.templateData.status;
        this.templateCategory = this.templateData.category;
        this.templateRejection = this.templateData.rejected_reason;


        this.templateExampleValues = {};

        if (this.templateParamValues == null) {
          this.templateParamValues = {};
        }

        if (
          this.templateBody != null &&
          this.templateBody.example != null &&
          this.templateBody.example.body_text != null &&
          Array.isArray(this.templateBody.example.body_text) &&
          this.templateBody.example.body_text.length > 0
        ) {
          if (Array.isArray(this.templateBody.example.body_text[0])) {
            this.templateExampleValues.body = {};
            if (this.templateParamValues.body == null) {
              this.templateParamValues.body = {};
            }

            this.templateBody.example.body_text[0].forEach((v,i) => {
              this.templateExampleValues.body[i + 1] = v;
              if (this.templateParamValues.body[i + 1] == null) {
                this.templateParamValues.body[i + 1] = "";
              }
            });
          }
        }
      }

      this.updateParamValue(this.templateValues ?? {});
      this.updateParameteredTextDisplay();
      this.updateFields();
      this.updateIntialValue();
    }
  },
};
</script>
<style lang="scss" scoped>
.template-display-holder {
  ::v-deep(.params-form) {
    .form-fields-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &>.form-field-container {
        padding: .2rem;
        min-width: 10rem
      }
    }
  }
}


.display-inline {
  .template-container-holder {
    padding: .2rem;

    .template-container-content {
      max-width: 100%
    }
  }
}

.template-status-extras {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  padding: .5rem 1rem;

  .template-details-container,
  .template-status-container {
    display: flex;
    flex: 1 1 auto;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem
  }


}

.template-container-holder {
  ::v-deep(.template-container-body-text) {
    .templated-value {
      font-weight: 600;
      opacity: .9;
      font-size: 85%;
      padding: .2rem;

      &.temp-data {
        color: gray;
        font-weight: 300;
        opacity: .7;
      }
    }
  }

  --primary: #3b4a54;
  --app-background: #eae6df;

  color: var(--primary);
  background-color: var(--WDS-app-wash, var(--app-background));

  padding: 1rem;
  display: flex;


  --incoming-background: white;
  --primary-strong: #111b21;
  --radius-bubble: 7.5px;
  --message-primary: #111b21;
  --secondary-lighter: #8696a0;

  .template-container-content {
    max-width: 65%;
    background-color: var(--incoming-background);
    padding-bottom: 8px;
    padding-top: 6px;
    padding-left: 9px;
    padding-right: 7px;

    box-sizing: border-box;

    position: relative;
    word-wrap: break-word;
    white-space: pre-wrap;

    position: relative;
    flex: none;
    font-size: 14.2px;
    line-height: 19px;
    color: var(--message-primary);
    border-radius: var(--radius-bubble);




    .template-container-header {
      padding-bottom: 5px;
      font-size: 15px;
      font-weight: 500;
      line-height: 19px;
      color: var(--primary-strong);
    }

    .template-container-footer {
      position: relative;
      word-wrap: break-word;
      white-space: pre-wrap;
      padding-top: 6px;
      padding-bottom: 2px;
      line-height: 17px;
      padding-left: 0;
      box-sizing: border-box;
      padding-right: 0;
      color: var(--secondary-lighter);
      font-size: .8125rem;
    }
  }
}
</style>