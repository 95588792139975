

<script>
import {
  displayObjectData,
  formatDate,
  generateFacebookLinkUrl,
manageFormSubmitButtons,
} from "@/helpers/common";
import FbPageAvatar from "@/components/facebook/FbPageAvatar";
import FbUserAvatar from "@/components/facebook/FbUserAvatar";
import UserAvatar from "@/components/data/UserAvatar";
import FbPostAvatar from "@/components/facebook/FbPostAvatar.vue";
import FbCommentSubmit from "@/components/facebook/FbCommentSubmit.vue";
import FbMessageSubmit from "@/components/facebook/FbMessageSubmit.vue";
import FbCommentSimpleListAvatar from "@/components/SimplifiedAction/FbCommentSimpleListAvatar.vue";
import MessageAttachmentDisplay from "@/components/MessageAttachmentDisplay.vue";


export default {
  name: "FbCommentAvatar",
  props: {
    organisationId: { type: String },
    commentId: { type: String },
    commentFbId: { type: String },
    navigateOnClick: { type: Boolean,default: () => false },
    navigateToParentOnClick: { type: Boolean,default: () => false },
    showLink: { type: Boolean,default: () => true },
    showDetails: { type: Boolean,default: () => false },
    showPage: { type: Boolean,default: () => false },
    showPost: { type: Boolean,default: () => false },
    showParentComment: { type: Boolean,default: () => false },
    showInCard: { type: Boolean,default: () => false },
    showActionMenu: { type: Boolean,default: () => false },
    showAddInfo: { type: Boolean,default: () => true },
    displaySmall: { type: Boolean,default: () => false },
    showShortDate: { type: Boolean,default: () => false },
    displaySimple: { type: Boolean,default: () => false },
    showPageInfo: { type: Boolean,default: () => true },
    showPageImage: { type: Boolean,default: () => false },
  },
  data() {
    return {
      comment: undefined,
      itemCount: 0,
      menuOptions: [],
    };
  },
  components: {
    FbPageAvatar,
    FbUserAvatar,
    UserAvatar,
    FbPostAvatar,
    FbCommentSubmit,
    FbMessageSubmit,
    FbCommentSimpleListAvatar,
    MessageAttachmentDisplay
  },
  watch: {
    commentId() {
      this.getComment();
    },
    "$store.state.lastUpdateNotification"(val) {
      if (
        val &&
        this.comment &&
        val.data &&
        Array.isArray(val.data) &&
        (
          (
            val.objectType == "FBCOMMENT_UPDATE" &&
            val.data.find((x) => x._id == this.comment._id)
          ) || (
            val.objectType == "FBCOMMENT_MULTIPLE_UPDATED" &&
            val.data.find((x) => x.items.find(y => y._id == this.comment._id) != null)
          )
        )
      ) {
        this.getComment();
      }
    },
  },
  mounted() {
    this.getComment();
  },
  emits: ["itemClick","avatarUpdated"],
  methods: {
    emitClick(e) {
      this.$emit("itemClick",e);
    },
    messageSubmitted() {
      this.getComment(true);
      this.$emit("avatarUpdated",{ action: "messageSubmitted" });
    },
    commitSubmitted() {
      this.getComment(true);
      this.$emit("avatarUpdated",{ action: "commitSubmitted" });
    },
    deleteConfirmed() {
      this.$store
        .dispatch("fbComment/deleteRemote",{ id: this.commentId })
        .then((d) => {
          this.getComment(true);
        });
    },
    hideConfirmed() {
      this.$store
        .dispatch("fbComment/updateRemote",{
          id: this.commentId,
          data: { hidden: true },
        })
        .then((d) => {
          this.getComment(true);
        });
    },
    showConfirmed(id) {
      this.$store
        .dispatch("fbComment/updateRemote",{
          id: this.commentId,
          data: { hidden: false },
        })
        .then((d) => {
          this.getComment(true);
        });
    },
    updateMenuList() {
      this.menuOptions = [];
      if (this.comment?.isRead != true) {
        this.menuOptions.push({
          text: this.$i18n?.t("general.markAsRead"),
          key: "markAsRead",
        });
      }

      this.menuOptions.push({
        text: this.$i18n?.t("general.respondToComment"),
        key: "respondToComment",
      });

      if (
        this.comment?.data?.can_reply_privately == true &&
        this.comment.ownerSent != true
      ) {
        this.menuOptions.push({
          text: this.$i18n?.t("general.privateReply"),
          key: "privateReply",
        });
      }

      if (this.comment?.data?.can_remove == true) {
        this.menuOptions.push({
          text: this.$i18n?.t("general.deleteComment"),
          key: "deleteComment",
        });
      }

      if (
        this.comment?.data?.can_hide == true &&
        this.comment?.data?.is_hidden == true
      ) {
        this.menuOptions.push({
          text: this.$i18n?.t("general.showComment"),
          key: "showComment",
        });
      } else if (
        this.comment?.data?.can_hide == true &&
        this.comment?.data?.is_hidden == false
      ) {
        this.menuOptions.push({
          text: this.$i18n?.t("general.hideComment"),
          key: "hideComment",
        });
      }
    },
    clickMenuAction(val) {
      switch (val?.val) {
        case "markAsRead":
          this.markAsRead(val?.event);
          break;
        case "respondToComment":
          if (this.$refs.submitCommit) {
            const comment = Array.isArray(this.$refs.submitCommit)
              ? this.$refs.submitCommit[0]
              : this.$refs.submitCommit;
            comment?.showPopup();
          }
          break;
        case "privateReply":
          if (this.$refs.submitMesssage) {
            const message = Array.isArray(this.$refs.submitMesssage)
              ? this.$refs.submitMesssage[0]
              : this.$refs.submitMesssage;
            message?.showPopup();
          }
          break;
        case "deleteComment":
          if (this.$refs.deleteConfirmation) {
            const conf = Array.isArray(this.$refs.deleteConfirmation)
              ? this.$refs.deleteConfirmation[0]
              : this.$refs.deleteConfirmation;
            conf?.displayDialog();
          }
          break;
        case "hideComment":
          if (this.$refs.hideConfirmation) {
            const conf = Array.isArray(this.$refs.hideConfirmation)
              ? this.$refs.hideConfirmation[0]
              : this.$refs.hideConfirmation;
            conf?.displayDialog();
          }
          break;
        case "showComment":
          if (this.$refs.showConfirmation) {
            const conf = Array.isArray(this.$refs.showConfirmation)
              ? this.$refs.showConfirmation[0]
              : this.$refs.showConfirmation;
            conf?.displayDialog();
          }
          break;
      }
    },
    markAsRead(e) {
      manageFormSubmitButtons(e,() => {
        return this.$store
          .dispatch("fbComment/markAsRead",{ id: this.commentId })
          .then(() => {
            this.getComment(true);
            this.$emit("avatarUpdated",{ action: "markAsRead" });
          });
      });
    },
    navigateToExternal() {
      if (this.post?.data?.permalink_url) {
        return window.open(
          generateFacebookLinkUrl(this.comment.data.permalink_url),
          "_blank"
        );
      }
    },
    formatDate(val) {
      return formatDate(val,this.showShortDate ? "LT" : undefined);
    },
    navigateToComment() {
      if (this.navigateOnClick == false) {
        return;
      }

      const query = {
        organisationId: this.organisationId,
        parentCommentId: this.parentId,
      };

      Object.entries(query).forEach(([key,val]) => {
        if (val == null) {
          delete query[key];
        }
      });

      this.$router.push({
        name: "pageCommentDetails",
        params: {
          id: this.comment.fbPageId,
          commentId: this.commentId,
          postId: this.comment.fbPostId,
        },
        query,
      });
    },
    getComment(force = false) {
      if (this.commentId == null && this.commentFbId == null) {
        return;
      }

      if (this.commentFbId) {
        return this.$store
          .dispatch("fbComment/getAll",{
            options: {
              filter: { fbId: { $regex: this.commentFbId } },
            },
          })
          .then((d) => {
            if (d && d.data && d.data.length > 0) {
              this.comment = d.data[0];
              this.updateMenuList();
            }
          });
      } else if (this.commentId) {
        return this.$store
          .dispatch("fbComment/getById",{
            organisationId: this.organisationId,
            id: this.commentId,
            force,
            mergeRequest: true
          })
          .then((d) => {
            this.comment = d;
            this.updateMenuList();
          });
      }
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.comment.data ?? {});
    },
  },
};
</script>

<template>
  <CustomAvatar v-if="displaySimple != true" class="fb-comment-avatar" :class="{
    'not-read-comment': comment?.isRead != true,
    'comment-own-sent': comment?.ownerSent,
    'clickable-avatar': navigateOnClick,
    'comment-deleted': comment?.isRemoteDeleted,
    'comment-isHidden': comment?.data?.is_hidden,
  }" :readyToDisplay="comment != null" :dataIdentifier="commentId" :displaySmall="displaySmall"
    :showDetails="showDetails" :hasLink="comment?.data?.permalink_url != null" :showLink="showLink"
    :showInCard="showInCard" :showImage="false" :showActionMenu="showActionMenu" :actionMenuOptions="menuOptions"
    @linkClick="navigateToExternal" @actionMenuClick="clickMenuAction" @itemClick="navigateToComment"
    @refreshClick="getComment(true)">
    <template v-slot:mainData>
      <div class="comment-inline-container">
        <div class="comment-data-container">
          <div class="comment-parents-container">
            <div class="comment-page-container">
              <FbPageAvatar v-if="showPage" :organisationId="organisationId" :pageId="comment?.fbPageId"
                :displaySmall="true"></FbPageAvatar>
            </div>
            <div class="comment-post-container">
              <FbPostAvatar v-if="showPost" :organisationId="organisationId" :pageId="comment?.fbPageId"
                :postId="comment?.fbPostId" :displaySmall="true" :showStatus="false"></FbPostAvatar>
            </div>
            <div class="comment-parent-container">
              <FbCommentAvatar v-if="showParentComment && comment.parentCommentId" :commentId="comment.parentCommentId"
                :organisationId="organisationId" :navigateToParentOnClick="navigateToParentOnClick"
                :navigateOnClick="navigateToParentOnClick" :postId="comment?.fbPostId" :pageId="comment?.fbPageId"
                :displaySmall="true" :showInCard="true" :showAddInfo="false"></FbCommentAvatar>
            </div>
          </div>
          <div class="comment-avatar-container">
            <div class="comment-container" :class="{ 'child-comment': comment?.parentCommentId != null }">
              <div class="comment-owners-container">
                <div class="comment-fb-user-content">
                  <FbUserAvatar v-if="comment?.ownerSent != true" :fbUserId="comment.fromId"
                    :organisationId="organisationId" :pageId="comment?.fbPageId" :displaySmall="true"></FbUserAvatar>
                </div>
                <div class="owner-agent-container">
                  <UserAvatar v-if="comment.agentId && comment?.ownerSent" :userId="comment.agentId" :displaySmall="true">
                  </UserAvatar>
                </div>
              </div>
              <div class="comment-content" v-if="comment?.isRemoteDeleted != true">
                <div>{{ comment.message }}</div>
                <MessageAttachmentDisplay v-if="comment.attachment" :attachments="[comment.attachment]">
                </MessageAttachmentDisplay>
              </div>
              <div v-else>
                <i class="fa-solid fa-trash"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:dataDetails>
      <div class="comment-dates-container">
        <i class="fa-regular fa-calendar"></i>
        {{ this.formatDate(comment.createdTime) }}
      </div>
    </template>
    <template v-slot:additionalContainers>
      <div class="comment-icons-container" v-if="showAddInfo == true">
        <div class="comment-icon-container">
          <i v-if="comment.isRead" class="fa-solid fa-check-double"></i>
          <i v-else class="fa-solid fa-xmark"></i>
        </div>
        <div class="comment-icon-container">
        </div>
      </div>
    </template>
    <template v-slot:additionalButtons>
      <FbCommentSubmit ref="submitCommit" v-if="comment.ownerSent != true" :postId="comment?.fbPostId"
        :commentId="comment?._id" @submited="commitSubmitted"></FbCommentSubmit>
      <FbMessageSubmit ref="submitMesssage" v-if="comment.ownerSent != true" :commentId="comment?._id"
        :fbUserId="comment.fromId" @submited="messageSubmitted"></FbMessageSubmit>
      <ConfirmationDialog ref="deleteConfirmation" :id="comment._id" @confirmed="(e) => deleteConfirmed(e)">
      </ConfirmationDialog>
      <ConfirmationDialog ref="hideConfirmation" :id="comment._id" @confirmed="(e) => hideConfirmed(e)">
      </ConfirmationDialog>
      <ConfirmationDialog ref="showConfirmation" :id="comment._id" @confirmed="(e) => showConfirmed(e)">
      </ConfirmationDialog>
    </template>
    <template v-slot:popupData>
      <div v-html="getObjectDefaultDisplay()"></div>
    </template>
  </CustomAvatar>
  <FbCommentSimpleListAvatar v-if="displaySimple == true" :organisationId="organisationId" :showPage="showPage"
    :showParentComment="showParentComment" :comment="comment" :showPageInfo="showPageInfo" :showPageImage="showPageImage"
    @itemClick="emitClick"></FbCommentSimpleListAvatar>
</template>

<style lang="scss" scoped>
.fb-comment-avatar {
  width: fit-content;
  max-width: 35rem;
  margin-right: auto;

  &.comment-own-sent {
    margin-left: auto;
    margin-right: 0.2rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 0;
  }

  &.comment-isHidden {
    opacity: 0.7;
  }

  &.comment-deleted {
    opacity: 0.5;
  }
}

.comment-owners-container {
  display: flex;
  align-items: center;
}

.comment-parents-container {
  display: flex;
  align-items: center;
}

.comment-dates-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray;

  &>* {
    margin: 0.3rem;
    font-size: 0.8rem;
  }
}

.comment-avatar-container {
  display: flex;
  flex-direction: column;
}

.comment-content {
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  margin: 0.2rem;

  img {
    max-width: 25rem;
  }
}

.child-comment {
  margin-left: 1rem;
}

.comment-icons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .comment-statistic-holder {
    display: flex;
    align-items: center;
  }
}

.comment-icon-container {
  font-size: 1rem;
  color: gray;
  align-items: center;
  display: flex;
  margin: 0.2rem;


}

.comment-inline-container {
  display: flex;
  align-items: center;
}
</style>