<template>
  <div v-if="attachment">
    <ItemDisplay :itemData="attachmentData" :itemName="attachmentName" :itemType="attachmentType" :details="details" @manageError="manageError"
    @downloadMedia="(e)=>$emit('downloadMedia',e,attachmentData)"  @showNewTab="(e)=>$emit('showNewTab',e,attachmentData)">
    </ItemDisplay>
  </div>
</template>

<script>
import axios from 'axios';
import ItemDisplay from './ItemDisplay.vue';

export default {
  name: "WaWebMessageAtachmentDisplayComponent",
  props: {
    attachment: { type: Object,default: () => undefined },
  },
  data() {
    return {
      details: undefined,
      attachmentData: undefined,
      attachmentName: undefined,
      attachmentType: undefined,
    };
  },
  components: { ItemDisplay },
  emits: ['downloadMedia','showNewTab'],
  watch: {
    attachment() {
      this.getAttachmentType();
      this.getAttachmentName();
      this.getAttachmentData();
      this.getAttachmenDetails();
    }
  },
  mounted() {
    this.getAttachmentType();
    this.getAttachmentName();
    this.getAttachmentData();
    this.getAttachmenDetails();
  },
  methods: {
    manageError(e) {
      this.attachmentData = null;
    },
    getAttachmentType() {
      if (this.attachment) {
        if ((this.attachment.type == 'story_mention' && this.attachment.payload?.url) ||
          this.attachment.mention?.link ||
          this.attachment.reply_to?.link
        ) {
          if (this.attachment.mention?.link == '' || this.attachment.reply_to?.link == '') {
            this.attachmentType = 'notAvailable';
            return;
          }
          axios.get(this.attachment.reply_to?.link ?? this.attachment.mention?.link ?? this.attachment.payload?.url,{ responseType: 'arraybuffer' })
            .then(d => {
              if (d.headers && d.headers['content-type'] && d.headers['content-type'].startsWith('video')) {
                this.attachmentType = 'video';
              } else {
                this.attachmentType = 'image';
              }
            }).catch(() => {
              this.attachmentType = 'image';
            });
          return;
        } else if (
          (this.attachment.mime_type && this.attachment.mime_type.toLowerCase().startsWith('image')) ||
          this.attachment.image_data != null ||
          (this.attachment.media != null && this.attachment.media.image != null)
        ) {
          this.attachmentType = 'image';
          return;
        } else if (this.attachment.mime_type && this.attachment.mime_type.toLowerCase().startsWith('video')) {
          this.attachmentType = 'video';
          return;
        }else if (this.attachment.mime_type && this.attachment.mime_type.toLowerCase().startsWith('audio')) {
          this.attachmentType = 'audio';
          return;
        }
        this.attachmentType = this.attachment.type;
        return;
      }
    },
    getAttachmentName() {
      if (this.attachment) {
        if (this.attachment.type == 'story_mention' || (this.attachment.mention?.link)) {
          this.attachmentName = this.$i18n.t('general.story_mention')
          return;
        } else if (this.attachment.mime_type == 'image/gif') {
          this.attachmentName = 'GIF';
          return;
        }
        this.attachmentName = this.attachment?.type ?? "";
        return;
      }
    },
    getAttachmentData() {
      if (this.attachment) {
        if (this.attachment.type == 'story_mention' || this.attachment.mention?.link != null || this.attachment.reply_to?.link != null) {
          this.attachmentData = this.attachment.payload?.url ?? this.attachment.mention?.link ?? this.attachment.reply_to?.link;
          return;
        } else if (this.attachment.mime_type == 'image/gif') {
          this.attachmentData = this.attachment.image_data?.url;
          return;
        } else if (this.attachment.mime_type == 'image/jpeg') {
          this.attachmentData = this.attachment.image_data?.url;
          return;
        } else if (this.attachment.mime_type && this.attachment.mime_type.startsWith('audio')) {
          this.attachmentData = this.attachment.file_url;
          return;
        } else if (this.attachment.type == 'image') {
          this.attachmentData = this.attachment.payload?.url;
          return;
        } else if (this.attachment.image_data != null) {
          this.attachmentData = this.attachment.image_data?.url;
          return;
        } else if (this.attachment.media?.image != null) {
          this.attachmentData = this.attachment.media?.image?.src;
          return;
        } else if (this.attachment.type == 'location') {
          this.attachmentData = this.attachment.payload;
          return;
        } else if (this.attachment.mime_type && this.attachment.mime_type.toLowerCase().startsWith('video')) {
          this.attachmentData = this.attachment.file_url;
          return;
        } else if (this.attachment.type == 'document') {
          this.attachmentData = this.attachment.payload;
          return;
        } 
        this.attachmentData = this.attachment?.image_data?.url ?? this.attachment?.payload?.url;
        return;
      }
    },
    getAttachmenDetails() {
      if (this.attachment) {
        if (this.attachment.mime_type == 'image/gif') {
          this.details = this.attachment.name;
          return;
        } else if (this.attachment.mime_type == 'image/jpeg') {
          this.details = this.attachment.name;
          return;
        }
        this.details = this.attachment?.name;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-body {
  max-width: 20rem;

  .attachment-content {
    max-width: 100%;
    max-height: 10rem;
    margin: .5rem;
  }
}
</style>