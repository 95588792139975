import axios from "../../../axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule("/api/wa/phones", {
  actions: {
    getVerify({ dispatch }, { id,data, organisationId }) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest({
          url: `/api/wa/phones/${id}/verify`,
          params: { organisationId, data },
        })
        .then((d) => {
          return d.data;
        });
    },

    postVerify({ dispatch }, { id, organisationId, code }) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/wa/phones/${id}/verify`,
          params: { organisationId },
          data: { code },
        })
        .then((d) => {
          return d.data;
        });
    },

    postRegister({ dispatch }, { id, organisationId, pin }) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/wa/phones/${id}/register`,
          params: { organisationId },
          data: { pin },
        })
        .then((d) => {
          return d.data;
        });
    },

    postUnregister({ dispatch }, { id, organisationId }) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest({
          method: "DELETE",
          url: `/api/wa/phones/${id}/register`,
          params: { organisationId },
        })
        .then((d) => {
          return d.data;
        });
    },

    getProfile({ dispatch }, { id, organisationId, force }) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest({
          url: `/api/wa/phones/${id}/profile`,
          params: { organisationId, force },
        })
        .then((d) => {
          return d.data;
        });
    },

    postProfile({ dispatch }, { id, organisationId, data }) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/wa/phones/${id}/profile`,
          params: { organisationId },
          data,
        })
        .then((d) => {
          return d.data;
        });
    },

    getCompliance({ dispatch }, { id, organisationId, force }) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest({
          url: `/api/wa/phones/${id}/compliance`,
          params: { organisationId, force },
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBWAPHONE",
});

export default module;
