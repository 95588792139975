<template>
  <div class="form-component-container" v-if="displayFields && displayFields.length > 0">
    <form :autocomplete="options?.autocomplete" @submit.stop.prevent="(e) => submitForm(e)" v-if="displayValue && displayFields">
      <div class="form-body-container">
        <CustomFormInputGroup v-model="displayValue" :fields="displayFields" :validation="validation"
          @changeFieldDisplay="(d) => updateFieldDisplay(d)">
          <template v-for="field in displayFields" :field="field.key" #[field.type]>
            <slot :name="field.type" :data="displayValue"></slot>
          </template>
        </CustomFormInputGroup>
      </div>
      <slot name="submitButton" v-if="showSubmitButton">
        <CustomButton class="form-submit-button" buttonType="submit" iconClass="fa-regular fa-circle-check"
          :disabled="disabled"></CustomButton>
      </slot>
    </form>
  </div>
</template>
<script>

export default {
  name: "CustomFormCmp",
  props: {
    initialValue: { type: Object,default: () => undefined },
    options: { type: Object,default: () => undefined },
    fields: { type: Array,default: () => undefined },
    showSubmitButton: { type: Boolean,default: () => true },
    disabled: { type: Boolean,default: () => false },
  },
  data() {
    return {
      displayValue: undefined,
      displayFields: undefined,
      validation: undefined
    };
  },
  watch: {
    initialValue(val) {
      if (this.initialValue) {
        this.displayValue = { ...this.initialValue };
      } else {
        this.displayValue = {};
      }
      this.initForm();
    },
    fields(val) {
      if (this.fields && Array.isArray(this.fields)) {
        this.displayFields = [...this.fields];
      } else {
        this.displayFields = [];
      }
      this.initForm();
    },
  },
  mounted() {
    this.displayValue = { ...(this.initialValue ?? {}) };
    this.displayFields = [...(this.fields ?? [])];
    this.initForm();
  },
  emits: ["formSubmit"],
  methods: {
    async initForm() {
      if (this.displayFields) {
        this.displayValue = Object.fromEntries(this.displayFields.map((x) => {
          return [
            x.key,
            this.displayValue && this.displayValue[x.key] != null
              ? this.displayValue[x.key]
              : (this.initialValue != null ? this.initialValue[x.key] : undefined),
          ]
        }));

        if (this.displayFields != null) {
          this.displayFields.sort((a,b) => b.order == null ? 1 : (a.order == null ? -1 : (a.order - b.order)))
        }
      }
    },
    updateFieldDisplay(data) {
      this.displayFields = data;
    },
    async validateForm() {
      if (this.displayFields != null) {
        const validation = await Promise.all(
          this.displayFields
            .map(x => {
              if (x?.validate != null) {
                if (typeof x.validate == "string") {
                  return undefined;
                }
                if (typeof x.validate == "function") {
                  return Promise.resolve(x.validate(this.displayValue));
                }
              }
              return undefined;
            })
            .map(x => Promise.resolve(x))
        ).then(arr => arr.reduce((p,c) => {
          if (c != undefined) {
            Object.entries(c)
              .filter(([key,val]) => val != null)
              .map(([key,val]) => {
                if (p[key] == null) {
                  p[key] = [];
                }
                p[key].push(val);
              })
          }
          return p;
        },{})
        );

        if (Object.keys(validation).length == 0) {
          return undefined;
        } else {
          return validation;
        }
      }
      return undefined;
    },
    async submitForm(e) {
      if (this.disabled == true) {
        return;
      }
      if (e?.stopPropagation && e?.stopImmediatePropagation) {
        e?.stopPropagation();
        e?.stopImmediatePropagation();
      }
      const res = await this.validateForm();
      if (res == null) {
        this.$emit("formSubmit",this.displayValue,e);
        this.validation = undefined;
        return this.displayValue;
      } else {
        this.validation = res;
        return;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.form-component-container {
  .form-submit-button {
    &::v-deep(button) {
      min-width: 3rem;
      align-items: center;
      justify-content: center;
      font-size: 1rem;

      &.success-button {
        background-color: rgba(var(--vs-success), .9);
      }

      &.success-button:disabled {
        background-color: rgba(var(--vs-success), .8);
      }

      &.success-button:hover {
        background-color: rgba(var(--vs-success), 1);
      }
    }
  }

  .form-body-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: .2rem .3rem;
  }
}
</style>