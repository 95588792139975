<template>
  <CustomAvatar v-if="fbDataMessage == null" class="fb-message-avatar" :class="{
    'message-read': message?.isRead,
    'message-changed': message?.changed,
    'message-deleted': message?.deleted,
    'message-own-sent': message?.ownerSent,
    'clickable-avatar': navigateOnClick,
  }" :readyToDisplay="message != null" :dataIdentifier="messageId" :displaySmall="displaySmall"
    :showDetails="showDetails" :hasLink="message?.data?.permalink_url != null" :showLink="showLink"
    :showInCard="showInCard" :showImage="false" :showActionMenu="showActionMenu" :actionMenuOptions="menuOptions"
    @linkClick="navigateToExternal(conversationlink)" @actionMenuClick="clickMenuAction" @itemClick="navigateToMessage()"
    @refreshClick="getMessage(true)">
    <template v-slot:mainData>
      <div v-if="showReplyTo && message.replyTo" class="reply-to-container">
        <FbMessageAvatar :organisationId="this.organisationId" :messageId="message.replyTo" :displayToUser="false"
          :showDetails="false" :showActionMenu="false" :showAdditionalInfo="false" :displayAgent="false">
        </FbMessageAvatar>
      </div>
      <div class="participants-container">
        <div class="mesage-from-container" v-if="showUserInfo && displayToUser">
          <FbUserAvatar 
          :fbUserId="message.fromId" 
          :displaySmall="true"
          ></FbUserAvatar>
        </div>
        <div class="mesage-to-containers" v-if="showUserInfo &&
          (displayToUser || (message.toIds && message.toIds.length > 1))
          ">
          <div class="from-to-divider">
            <i class="fa-solid fa-angle-right"></i>
          </div>
          <FbUserAvatar v-for="id in message.toIds" :key="id" 
          :fbUserId="id" 
          :showImage="true" 
          :displaySmall="true">
          </FbUserAvatar>
        </div>
      </div>
    </template>
    <template v-slot:dataDetails>
      <div class="message-avatar-details">
        <div class="fb-message-container" v-if="message.deleted != true">
          <span class="message-status" v-if="message?.ownerSent != true">
            <i v-if="message?.isRead != true" class="fa-solid fa-check"
              :class="{ 'not-read': message?.isRead != true }"></i>
            <i v-else class="fa-solid fa-check-double"></i>
          </span>
          {{ message.message }}
          <MessageAttachmentDisplay v-if="message.attachments" :attachments="message.attachments">
          </MessageAttachmentDisplay>
          <MessageShareDisplay v-if="message.shares" :shares="message.shares"></MessageShareDisplay>
        </div>
        <div v-else>
          <i v-if="message.deleted" class="fa-solid fa-trash"></i>
        </div>
        <div class="fb-message-details-container">
          <div class="message-details-item">
            <div class="message-date-container">
              {{ formatDate(message.createdTime) }}
            </div>
            <div class="mesage-agent-containers" v-if="message?.ownerSent && displayAgent">
              <UserAvatar v-if="message.agentId" :userId="message.agentId" :displaySmall="true"></UserAvatar>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="showAdditionalInfo" v-slot:additionalContainers>
      <div class="fb-message-details-container">
        <i v-if="message.changed" class="fa-solid fa-rotate"></i>
        <i v-if="message.isRead" class="fa-solid fa-check-double"></i>
        <div>
          <CustomTooltip :text="message.events.join(', ')" position="bottom" :active="message.events.length > 0">
            <span class="message-statistic-holder">
              <i class="fa-regular fa-note-sticky"></i>
              <span> {{ message.events.length }}</span>
            </span>
          </CustomTooltip>
        </div>
        <div>
          <CustomTooltip v-if="message && message.tags" :text="message.tags.join(', ')" position="bottom"
            :active="message.tags.length > 0">
            <span class="message-statistic-holder">
              <i class="fa-solid fa-tag"></i>
              <span> {{ message.tags?.length }}</span>
            </span>
          </CustomTooltip>
        </div>
        <div>
          <CustomTooltip :text="message.attachments.join(', ')" position="bottom"
            :active="message.attachments.length > 0">
            <span class="message-statistic-holder">
              <i class="fa-solid fa-paperclip"></i>
              <span> {{ message.attachments?.length }}</span>
            </span>
          </CustomTooltip>
        </div>
        <div>
          <CustomTooltip :text="message.shares.join(', ')" position="bottom" :active="message.shares.length > 0">
            <span class="message-statistic-holder">
              <i class="fa-solid fa-share"></i>
              <span> {{ message.shares?.length }}</span>
            </span>
          </CustomTooltip>
        </div>
      </div>
    </template>
    <template v-slot:additionalButtons>
      <FbMessageSubmit ref="submitMesssage" :messageId="message?._id" @submited="messageSubmitted"></FbMessageSubmit>
    </template>
    <template v-slot:popupData>
      <div v-html="getObjectDefaultDisplay()"></div>
    </template>
  </CustomAvatar>
  <div v-else class="fb-action-message-container">
    <span v-html="fbDataMessage"></span>
    <FbCommentAvatar v-if="releatedCommentFbId" :showParentComment="true" :commentFbId="releatedCommentFbId"
      :displaySmall="true" :showInCard="true" :showAddInfo="false"></FbCommentAvatar>
  </div>
</template>

<script>
import FbUserAvatar from "@/components/facebook/FbUserAvatar";
import UserAvatar from "@/components/data/UserAvatar";

import {
  displayObjectData,
  fbMessageDataRegex,
  fbMessageGenericDataRegex,
  generateFacebookLinkUrl,
manageFormSubmitButtons,
} from "@/helpers/common";
import { formatDate } from "@/helpers/common";
import FbMessageSubmit from "./FbMessageSubmit.vue";
import MessageAttachmentDisplay from "@/components/MessageAttachmentDisplay";
import MessageShareDisplay from "@/components/MessageShareDisplay";

export default {
  name: "FbMessageAvatar",
  props: {
    organisationId: { type: String },
    messageId: { type: String },
    navigateOnClick: { type: Boolean,default: () => false },
    displayToUser: { type: Boolean,default: () => false },
    displayAgent: { type: Boolean,default: () => true },
    showDetails: { type: Boolean,default: () => true },
    showInCard: { type: Boolean,default: () => false },
    showLink: { type: Boolean,default: () => false },
    displaySmall: { type: Boolean,default: () => false },
    showActionMenu: { type: Boolean,default: () => false },
    displaySimple: { type: Boolean,default: () => false },
    showUserInfo: { type: Boolean,default: () => true },
    showAdditionalInfo: { type: Boolean,default: () => true },
    showShortDate: { type: Boolean,default: () => false },
    showReplyTo: { type: Boolean,default: () => false },
  },
  data() {
    return {
      fbDataMessage: undefined,
      releatedPostFbId: undefined,
      releatedCommentFbId: undefined,
      message: undefined,
      menuOptions: [],
      defaultMenuOptions: [
        { text: this.$i18n?.t("general.details"),key: "navigateToDetails" },
        { text: this.$i18n?.t("general.markAsRead"),key: "markAsRead" },
        { text: this.$i18n?.t("general.privateReply"),key: "privateReply" },
      ],
    };
  },
  components: {
    FbUserAvatar,
    UserAvatar,
    FbMessageSubmit,
    MessageAttachmentDisplay,
    MessageShareDisplay,
  },
  watch: {
    "$store.state.lastUpdateNotification"(val) {
      if (
        val &&
        this.message &&
        val.objectType == "FBMESSAGE_UPDATED" &&
        val.data &&
        Array.isArray(val.data) &&
        val.data.find((x) => x._id == this.message._id)
      ) {
        this.getMessage();
      }
    },
    messageId() {
      this.getMessage();
    },
  },
  computed: {
    messagePopup() {
      if (this.$refs.submitMesssage) {
        return Array.isArray(this.$refs.submitMesssage)
          ? this.$refs.submitMesssage[0]
          : this.$refs.submitMesssage;
      }
      return null;
    },
  },
  mounted() {
    this.getMessage();
  },
  methods: {
    clickMenuAction(val) {
      switch (val?.val) {
        case "navigateToDetails":
          this.navigateToMessage(true);
          break;
        case "markAsRead":
          this.markAsRead(val?.event);
          break;
        case "privateReply":
          if (this.messagePopup) {
            this.messagePopup.showPopup();
          }
          break;
      }
    },
    messageSubmitted(d) {
      if (this.messagePopup) {
        this.messagePopup.closePopup();
      }
    },
    markAsRead(e) {
      manageFormSubmitButtons(e,() => {
        return this.$store
          .dispatch("fbMessage/markAsRead",{ id: this.messageId })
          .then(() => {
            this.getMessage();
          });
      });
    },
    formatDate(val) {
      return formatDate(val,this.showShortDate ? "LT" : undefined);
    },
    navigateToExternal(link) {
      return window.open(generateFacebookLinkUrl(link),"_blank");
    },
    navigateToMessage(force = false) {
      if (!(this.navigateOnClick == true || force == true)) {
        return;
      }
      const query = {};
      if (this.organisationId) {
        query.organisationId = this.organisationId;
      }

      this.$router.push({
        name: "messageDetails",
        params: {
          messageId: this.messageId,
          id: this.message.fbPageId,
          conversationId: this.message.fbConversationId,
        },
        query,
      });
    },
    getMessage(force = false) {
      return this.$store
        .dispatch("fbMessage/getById",{
          organisationId: this.organisationId,
          id: this.messageId,
          force,mergeRequest: true
        })
        .then(async (d) => {
          this.message = d;
          this.fbDataMessage = undefined;
          if (d?.message) {
            const res = fbMessageDataRegex.exec(d.message);
            if (res && res[0]) {
              this.releatedPostFbId = res[1];
              this.releatedCommentFbId = res[2];
            }

            const genericLink = fbMessageGenericDataRegex.exec(d.message);
            if (genericLink && genericLink[0]) {
              this.fbDataMessage = d.message.replace(
                fbMessageGenericDataRegex,
                `<a href="${genericLink[1]}" target="_blank">link</a>`
              );
            }
          }

          this.menuOptions = this.defaultMenuOptions.filter((x) => {
            if (x.key == "markAsRead") {
              return this.message?.isRead != true;
            }
            return true;
          });
          return d;
        });
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.message.data ?? {},undefined,"message");
    },
  },
};
</script>

<style lang="scss" scoped>
.fb-message-avatar {
  display: inline-flex;
  margin: 0.2rem;
  margin-right: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  padding: 0.2rem 0.5rem;
  border-top-left-radius: 0;
  max-width: 25rem;

  &.message-own-sent {
    margin-left: auto;
    margin-right: 0.2rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 0;
  }

  .participants-container {
    display: flex;
    align-items: center;

    &>div {
      display: flex;
    }

    .from-to-divider {
      display: flex;
      align-items: center;
      margin: 0.2rem;
    }
  }

  .fb-message-container {
    margin: 0.3rem 0.2rem;
  }

  &::v-deep(.data-details-container) {
    max-width: 20rem;
  }
}

.message-status {
  width: 0.7rem;
  height: 0.7rem;
  flex: 0 0 auto;
}

.message-avatar-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  cursor: pointer;
  flex: 1;
}

.fb-message-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 0.8rem;
  color: gray;

  &>* {
    margin: 0.1rem;
  }

  .message-details-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.message-date-container {
  display: inline-flex;
  align-items: flex-end;
}

.message-statistic-holder {
  display: inline-flex;
  align-items: center;


}

.fb-action-message-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 30rem;
  font-size: 0.6rem;
}
</style>