<template>
    <CustomPopup ref="messagePopup" :showButton="false" :title="$t('general.submitMessage')">
        <CustomForm :fields="fields" :initialValue="initVal" @formSubmit="(val,e) => submitMessage(val,e)">
            <template v-slot:submitButton>
                <CustomButton buttonType="submit" buttonClass="success-button" buttonText="general.submit"></CustomButton>
            </template>
        </CustomForm>
    </CustomPopup>
</template>

<script>
import { manageFormSubmitButtons } from '../../helpers/common';

export default {
    name: "FbMessageComponent",
    props: {
        conversationId: { type: String },
        messageId: { type: String },
        igMessageId: { type: String },
        postId: { type: String },
        commentId: { type: String },
        fbUserId: { type: String },
        pageId: { type: String },
        showTypeSelector: { type: Boolean,default: () => false },
        showTagSelector: { type: Boolean,default: () => false },
    },
    data() {
        return {
            fields: [],
            initVal: {
                message: "",
            },
        };
    },
    watch: {},
    mounted() {
        this.updateForm();
    },
    emits: ["submited"],
    computed: {
        messagePopup() {
            if (this.$refs.messagePopup) {
                return Array.isArray(this.$refs.messagePopup)
                    ? this.$refs.messagePopup[0]
                    : this.$refs.messagePopup;
            }
            this.$emit("submited");
            return undefined;
        },
    },
    methods: {
        updateForm() {
            const tempFields = [];
            const defaultVals = {};
            if (this.showTypeSelector) {
                defaultVals.type = "RESPONSE";
                tempFields.push({
                    type: "select",
                    key: "type",
                    label: this.$i18n?.t("model.basic-type"),
                    options: [
                        { label: this.$i18n?.t("model.message-type-RESPONSE"),val: "RESPONSE" },
                        { label: this.$i18n?.t("model.message-type-UPDATE"),val: "UPDATE" },
                        {
                            label: this.$i18n?.t("model.message-type-MESSAGE_TAG"),
                            val: "MESSAGE_TAG",
                        },
                    ],
                });
            }
            if (this.showTagSelector) {
                defaultVals.tag = "";
                tempFields.push({
                    type: "select",
                    key: "tag",
                    label: this.$i18n?.t("model.basic-tag"),
                    options: [
                        { label: this.$i18n?.t("general.unselected"),val: "" },
                        {
                            label: this.$i18n?.t("model.message-tag-ACCOUNT_UPDATE"),
                            val: "ACCOUNT_UPDATE",
                        },
                        {
                            label: this.$i18n?.t("model.message-tag-CONFIRMED_EVENT_UPDATE"),
                            val: "CONFIRMED_EVENT_UPDATE",
                        },
                        {
                            label: this.$i18n?.t("model.message-tag-CUSTOMER_FEEDBACK"),
                            val: "CUSTOMER_FEEDBACK",
                        },
                        {
                            label: this.$i18n?.t("model.message-tag-HUMAN_AGENT"),
                            val: "HUMAN_AGENT",
                        },
                        {
                            label: this.$i18n?.t("model.message-tag-POST_PURCHASE_UPDATE"),
                            val: "POST_PURCHASE_UPDATE",
                        },
                    ],
                });
            }
            if (this.commentId != null || this.postId != null) {
                tempFields.push({
                    type: "textarea",
                    key: "commentReply",
                    label: this.$i18n?.t("general.comment"),
                    placeholder: this.$i18n?.t("general.comment"),
                });
                defaultVals.commentReply = "";
            }
            tempFields.push({
                type: "textarea",
                key: "message",
                label: this.$i18n?.t("general.message"),
                placeholder: this.$i18n?.t("general.message"),
            });
            defaultVals.message = "";
            this.fields = tempFields;
            this.initVal = defaultVals;
        },
        async submitMessage(val,e) {
            manageFormSubmitButtons(e,() => {
                val = Object.fromEntries(Object.entries(val).filter(([key,val]) => !(val == null || (typeof val == "string" && !(val.length > 0)))));
                return this.$store
                    .dispatch("fbMessage/submit",{
                        fbUserId: this.fbUserId,
                        messageId: this.messageId,
                        conversationId: this.conversationId,
                        pageId: this.pageId,
                        commentId: this.commentId,
                        postId: this.postId,
                        data: val,
                    })
                    .then((d) => {
                        this.submitSuccess(d);
                    }).catch(() => null);
            })
        },
        submitSuccess(d) {
            this.messagePopup?.closePopup();
            this.updateForm();
        },
        showPopup(e) {
            this.messagePopup?.showPopup(e);
            this.updateForm();
        },
    },
};
</script>
