<template>
  <div v-if="isReady">
    <div class="table-header-actions">
      <div class="actions-container">
        <CustomButton v-if="remoteRequestEnabled && showRefreshButton" class="action-button"
          buttonClass="primary-button" :disabled="refreshOngoing" iconClass="fa-solid fa-rotate"
          @click.stop="(e) => refreshClicked(e)">
        </CustomButton>
        <CustomButton v-if="enableAdd" class="action-button" buttonClass="success-button" iconClass="fa-solid fa-plus"
          @click.stop="(e) => emit('addReq',e)">
        </CustomButton>
        <slot name="actionButtons"> </slot>
      </div>
      <CustomForm class="search-form" ref="formComponent" :fields="filterOptions" :initialValue="filterData"
        @formSubmit="(val,e) => formSubmit(val,e)">
        <template v-slot:submitButton>
          <CustomButton buttonType="submit" buttonClass="primary-button" :disabled="refreshOngoing"
            iconClass="fa-solid fa-magnifying-glass">
          </CustomButton>
        </template>
      </CustomForm>
      <CustomSort v-if="showSortButton" class="sort-options" :sortData="displaySort" :sortOptions="sortOptions"
        :disabled="refreshOngoing" @sortChanged="(val) => sortChanged(val)"></CustomSort>
    </div>
    <CustomPagination v-if="displayTableData && showPagination" ref="paginatation" :itemCount="itemCount"
      :pageIndex="pageIndex" :pageItemCount="pageItemCount" :pageDisplayCount="pageDisplayCount"
      :paginateScroll="paginateScroll" :displayReverse="displayReverse" :disabled="refreshOngoing"
      @pageChanged="(val) => changePage(val)" @scrollChanged="(val) => scrollChange(val)">
      <div ref="list" class="custom-table-container"
        :class="{ scrollTable: paginateScroll,displayReverse: displayReverse }" @scroll="(e) => tableScrolled(e)">
        <table>
          <thead v-if="hideHeader != true">
            <tr>
              <th v-for="header in headers" :key="header.key" :class="header.key + '-header-container'"
                @click.stop="(e) => emitRowClicked(e,header)">
                {{ header.data }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in displayTableData" :key="d.key" :id="d.key + '-row'" @click="(e) => emitRowClicked(e,d)"
              :class="{
    active: activeItem == d.key,
  }">
              <td v-for="header in headers" :key="d.key + header.key"
                @click="(e) => emitCellClicked(e,{ row: d,cell: header.key })" :class="{
    [header.key + '-data-container']: true,
  }">
                <slot :name="header.key" :data="d">
                  <span v-html="d.data[header.key]"></span>
                </slot>
              </td>
            </tr>
            <tr v-if="(displayTableData?.length ?? 0) == 0 && hideIfEmpty != true">
              <td :colspan="headers?.length ?? 1" class="data-not-found-col">
                <div class="data-not-found-container">
                  {{ i18nService.i18n.global.t("general.dataNotFound") }}
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    </CustomPagination>
    <div v-if="showPagination != true && displayTableData" ref="list" class="custom-table-container"
      :class="{ scrollTable: paginateScroll,displayReverse: displayReverse }" @scroll="(e) => tableScrolled(e)">
      <table>
        <thead v-if="hideHeader != true">
          <tr>
            <th v-for="header in headers" :key="header.key" :class="header.key + '-header-container'"
              @click.stop="(e) => emitRowClicked(e,header)">
              {{ header.data }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in displayTableData" :key="d.key" :id="d.key + '-row'" @click="(e) => emitRowClicked(e,d)"
            :class="{
    active: activeItem == d.key,
  }">
            <td v-for="header in headers" :key="d.key + header.key"
              @click="(e) => emitCellClicked(e,{ row: d,cell: header.key })" :class="{
    [header.key + '-data-container']: true,
  }">
              <slot :name="header.key" :data="d">
                <span v-html="d.data[header.key]"></span>
              </slot>
            </td>
          </tr>
          <tr v-if="(displayTableData?.length ?? 0) == 0 && hideIfEmpty != true">
            <td :colspan="headers?.length ?? 1" class="data-not-found-col">
              <div class="data-not-found-container">
                {{ i18nService.i18n.global.t("general.dataNotFound") }}
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref,reactive,computed,watch,defineProps,defineEmits,onMounted,getCurrentInstance,onBeforeUnmount } from "vue";
import { useRoute,useRouter } from "vue-router";

import CustomPagination from "@/components/CustomPagination.vue";
import CustomSort from "@/components/CustomSort.vue";
import i18nService from "@/controllers/i18nService";

import {
  scriptTagRegex,
  dateStringRegex,
  blockButtonEvent,
  manageFormSubmitButtons,
} from "@/helpers/common";
import moment from "moment";
import { store } from "@/store";
import { localStorageService } from "../controllers/localStorageService";
import { manageRefreshWebhookEvents } from "@/helpers/common";
import { clearRefreshWebhookEvents } from "@/helpers/common";

const route = useRoute();
const router = useRouter();
//props
const props = defineProps({
  data: { type: Array,default: () => [] },
  headers: { type: Array,default: () => [] },
  paging: { type: Object,default: () => { } },
  defaultDateFormat: { type: String,default: () => "LL" },
  enableAdd: { type: Boolean,default: () => false },
  sortOptions: { type: Array,default: () => [] },
  initialSort: { type: Object,default: () => { } },
  filterData: { type: Object,default: () => { } },
  filterOptions: { type: Array,default: () => [] },
  filterDataFormatter: { type: Function,default: (val) => val },
  hideHeader: { type: Boolean,default: () => false },
  displayReverse: { type: Boolean,default: () => false },
  paginateScroll: { type: Boolean,default: () => false },
  updatePathQuery: { type: Boolean,default: false },
  activeItem: { type: String,default: () => null },
  groupItems: { type: Boolean,default: () => false },
  itemGroupFunction: { type: Function,default: () => undefined },
  defaultFilter: {
    type: Object,
    default: () => {
      return {};
    },
  },
  getDataRequest: { type: Function,default: undefined },
  getDataFormatter: {
    type: Function,
    default: (val) =>
      val?.map((x) => {
        return { key: x._id,data: x };
      }) ?? [],
  },
  lastUpdateWatchFunction: { type: Function,default: () => undefined },
  hideIfEmpty: { type: Boolean,default: false },
  showRefreshButton: { type: Boolean,default: true },
  showSortButton: { type: Boolean,default: true },
  showPagination: { type: Boolean,default: true },
  initialDataKey: { type: String,default: () => undefined },
});

const tempDisplayOptions = {
  skip: props.paging?.skip ?? 0,
  limit: props.paging?.limit ?? 10,
};

let tempPageIndex = 0;
try {
  tempPageIndex = Math.floor(tempDisplayOptions.skip / tempDisplayOptions.limit);
} catch (e) {
  tempPageIndex = 0;
}

let avoidNotCompletedRequestTimer = undefined;
let refreshOngoing = ref(false);

const pageIndex = ref(tempPageIndex);
const pageDisplayCount = ref(1);
const pageItemCount = ref(tempDisplayOptions.limit);
const displayOptions = ref(tempDisplayOptions);
const itemCount = ref(null);
const displayFilter = ref(null);
const displayTableData = ref(null);
const tableData = ref([]);
const displaySort = ref({ ...(props.initialSort ?? { _id: -1 }) });
const isReady = ref(false);
const formComponent = ref();

// methods
function updatePageValues() {
  if (displayOptions.value) {
    if (
      displayOptions.value.skip != null &&
      displayOptions.value.limit != null
    ) {
      const limit = displayOptions.value.limit < 0 ? 1 : displayOptions.value.limit;
      pageIndex.value = Math.floor(displayOptions.value.skip / limit);
      pageItemCount.value = limit;
    }
  }
}
function formSubmit(val,e) {
  manageFormSubmitButtons(e,() => {
    val = Object.fromEntries(
      Object.entries(val).filter(([key,val]) => {
        return (
          val != null && (typeof val != "string" || val.trim().length > 0)
        );
      })
    );
    if (typeof props.filterDataFormatter == "function") {
      val = props.filterDataFormatter(val);
    }
    displayFilter.value = val;
    updateTableData();
  });
}
function sortChanged(val) {
  displaySort.value = val;
  updateTableData();
}
function changePage({ pageIndex,limit }) {
  displayOptions.value = { skip: pageIndex * limit,limit };
  updateTableData();
}

function scrollChange(e) {
  pageDisplayCount.value = e.pageDisplayCount;
  pageIndex.value = e.pageIndex;
  pageItemCount.value = e.itemPerPage;
  displayOptions.value = { skip: e.pageIndex * e.itemPerPage,limit: e.pageDisplayCount * e.itemPerPage };

  updateTableData();
}

async function updateTableData(event,options) {
  // Wait request to previous complete request
  if (refreshOngoing.value == true) {
    if (avoidNotCompletedRequestTimer) {
      clearTimeout(avoidNotCompletedRequestTimer);
    }
    avoidNotCompletedRequestTimer = setTimeout(() => {
      clearTimeout(avoidNotCompletedRequestTimer);
      updateTableData(event,options);
    },200);
    return;
  }

  const force = event != null;
  if (options?.initial == true) {
    emit("dataFilterChange",{
      ...(listRequestParams.value ?? {}),
      event,
      force,
    });
  }

  if (props.updatePathQuery) {
    const query = Object.assign({},route.query);
    const urlQueries = {};
    if (
      displayFilter.value != null &&
      Object.keys(displayFilter.value).length > 0
    ) {
      urlQueries.filter = displayFilter.value;
    }

    if (displayOptions.value != null) {
      if (
        displayOptions.value.skip != null &&
        displayOptions.value.skip > -1
      ) {
        urlQueries.skip = displayOptions.value.skip;
      }
      if (
        displayOptions.value.limit != null &&
        displayOptions.value.limit > -1
      ) {
        urlQueries.limit = displayOptions.value.limit;
      }
    }

    if (
      displaySort.value != null &&
      Object.keys(displaySort.value).length > 0
    ) {
      urlQueries.sort = displaySort.value;
    }

    if (urlQueries && Object.keys(urlQueries).length > 0) {
      query.table = JSON.stringify(urlQueries);
    } else {
      delete query.table;
    }
    router.push({ query });
  }

  refreshOngoing.value = true;
  blockButtonEvent(event);
  if (remoteRequestEnabled.value != true) {
    itemCount.value = props.data.length;
    tableData.value = [...props.data];
    if (displaySort.value) {
      const sortArray = Object.entries(displaySort.value);
      if (sortArray && sortArray.length > 0) {
        tableData.value = tableData.value.sort((a,b) => {
          if (
            sortArray &&
            sortArray[0] &&
            sortArray[0][0] &&
            a.source &&
            b.source &&
            a.source[sortArray[0][0]] &&
            b.source[sortArray[0][0]]
          ) {
            const dir = sortArray[0][1];
            if (sortArray[0][0]) {
              if (typeof a.source[sortArray[0][0]] == "string") {
                return (
                  dir *
                  `${a.source[sortArray[0][0]]}`.localeCompare(
                    b.source[sortArray[0][0]]
                  )
                );
              }
              return (
                dir * (a.source[sortArray[0][0]] - b.source[sortArray[0][0]])
              );
            }
          }
          return 0;
        });
      }
    }
    if (displayOptions.value) {
      updateDisplayData(
        tableData.value?.slice(
          displayOptions.value.skip ?? 0,
          (displayOptions.value.skip ?? 0) + (displayOptions.value.limit ?? 0)
        ) ?? []
      );
    } else {
      updateDisplayData(tableData.value);
    }

  } else {
    const prevSkip = listRequestParams.value?.skip ?? 0;
    const prevFilterKey = JSON.stringify(listRequestParams.value?.filter ?? {});

    if (props.initialDataKey && options?.initial == true) {
      const t = localStorageService.initialTableData;
      if (t && t[props.initialDataKey]) {
        try {
          const d = JSON.parse(t[props.initialDataKey]);
          manageGetData(d,prevFilterKey,force,prevSkip);
        } catch (e) {
          //
        }
      }
    }

    await props.getDataRequest({
      ...(listRequestParams.value ?? {}),
      force,
    })
      .then((d) => {
        if (props.initialDataKey) {
          const t = localStorageService.initialTableData;
          t[props.initialDataKey] = JSON.stringify(d);
          localStorageService.initialTableData = t;
        }
        manageGetData(d,prevFilterKey,force || options?.refreshByHook,prevSkip);
      })
      .catch((e) => {
        //
      });
  }
  blockButtonEvent(event,false);
  isReady.value = true;
  refreshOngoing.value = false;
}

async function manageGetData(d,prevFilterKey,force,prevSkip) {
  if (d.data) {
    let newData = [];
    if (
      props.getDataFormatter &&
      typeof props.getDataFormatter == "function"
    ) {
      newData = props.getDataFormatter(d.data);
    } else {
      newData = d.data;
    }
    tableData.value = newData;

  } else {
    tableData.value = [];
  }
  if (d.params) {
    emit("itemCountChanged",d.params.total);
    itemCount.value = d.params.total;
    if (d.params.skip != null && d.params.limit != null) {
      displayOptions.value.limit = d.params.limit;
      displayOptions.value.skip = d.params.skip;
    }
    if (d.params.sort) {
      displaySort.value = d.params.sort;
    }
  }
  updateDisplayData(tableData.value);
}
// Default data displays
async function updateDisplayData(val) {
  val = val ?? [];
  displayTableData.value = await Promise.all(
    val.map(async (x) => {
      const entries = await Promise.all(
        props.headers.map(async (h) => {
          const s = await showData(x.data,h);
          return [h.key,s];
        })
      );
      return {
        key: x.key,
        source: x.data,
        data: Object.fromEntries(entries),
      };
    })
  )
    .then((d) => props.displayReverse ? d.reverse() : d)
    .then((d) => {
      if (props.groupItems && props.itemGroupFunction) {
        const grouped = d.reduce((p,c) => {
          const day = moment(props.itemGroupFunction(c.source)).format("L");
          if (p[day] == null) {
            p[day] = [];
          }
          p[day].push(c);
          return p;
        },{});
        return Object.entries(grouped).reduce((p,[key,val]) => {
          p.push({
            type: "dayLabel",
            key,
            data: key,
          });
          return p.concat(val);
        },[]);
      }
      return d;
    });
}
async function getData(data,header) {
  if (data && header) {
    if (header.formatData && typeof header.formatData == "function") {
      const s = header.formatData(data);
      if (s instanceof Promise) {
        return await s;
      }
      return s;
    }
    return data[header.key];
  }
  return;
}
async function showData(data,header) {
  let s = await getData(data,header);
  if (s instanceof Date || RegExp(dateStringRegex).test(s)) {
    s = moment(s).format(props.defaultDateFormat);
  } else if (typeof s == "string") {
    s = s.replace(scriptTagRegex,"");
  } else if (typeof s == "boolean") {
    s = s
      ? '<i class="fa-regular fa-circle-check"></i>'
      : '<i class="fa-solid fa-xmark"></i>';
  }
  return s;
}

function refreshClicked(e) {
  updateTableData(e);
}
function emitRowClicked(e,d) {
  emit("rowClick",{ event: e,data: d });
}
function emitCellClicked(e,d) {
  emit("cellClick",{ event: e,data: d });
}
function tableScrolled(e) {
}
onMounted(() => {
  if (props.updatePathQuery) {
    const query = Object.assign({},route.query);
    if (query.table && typeof query.table == "string") {
      try {
        const { filter,skip,limit,sort } = JSON.parse(query.table);
        if (filter != null && typeof filter == "object") {
          displayFilter.value = filter;
        }

        if (skip != null && typeof skip == "number") {
          displayOptions.value.skip = skip;
        }
        if (limit != null && typeof limit == "number") {
          displayOptions.value.limit = limit;
        }
        if (sort != null && typeof sort == "object") {
          displaySort.value = sort;
        }
      } catch (e) {
        //
      }
    }
    router.push({ query });
  }

  updatePageValues();

  if (props.filterData) {
    let formVal = props.filterData;
    if (typeof props.filterDataFormatter == "function") {
      formVal = props.filterDataFormatter(formVal);
    }
    displayFilter.value = formVal;
  }
  updateTableData(null,{ initial: true });

  setTimeout(() => {
    if (paginationElement.value && props.displayReverse) {
      paginationElement.value.scrollToStart('bottom');
    }
  },600)


});

//computeds
const remoteRequestEnabled = computed(() => {
  return (
    props.getDataRequest != null && typeof props.getDataRequest == "function"
  );
});

const listRequestParams = computed(() => {
  return {
    filter: {
      ...(displayFilter.value ?? {}),
      ...(props.defaultFilter ?? {}),
    },
    skip: displayOptions.value?.skip,
    limit: displayOptions.value?.limit,
    sort: displaySort.value,
  };
});

const list = ref(null);

const paginatation = ref(null);
const paginationElement = computed(() => {
  return paginatation.value
    ? Array.isArray(paginatation.value)
      ? paginatation.value.length > 0
        ? paginatation.value[0]
        : undefined
      : paginatation.value
    : undefined;
});

const component = getCurrentInstance();

//emits

const emit = defineEmits(["addReq",
  "dataFilterChange",
  "rowClick",
  "cellClick",
  "itemCountChanged"]);





//watcher
watch(() => props.paging,(val) => {
  displayOptions.value = { skip: val?.skip ?? 0,limit: val?.limit ?? 10 };
  updatePageValues();
  updateTableData();
});
watch(() => props.initialSort,(val) => {
  sortChanged({ ...(val ?? {}) });
});
watch(() => props.data,() => {
  updateTableData();
});
watch(() => props.headers,() => {
  updateTableData();
});
watch(() => props.defaultFilter,() => {
  updateTableData();
});
watch(() => store.state.lastUpdateNotification,async (val) => {
  if (
    props.lastUpdateWatchFunction &&
    typeof props.lastUpdateWatchFunction == "function"
  ) {
    const test = await Promise.resolve(props.lastUpdateWatchFunction(val));
    if (test) {
      manageRefreshWebhookEvents("custom-table-" + component.uid.toString(),() => {
        updateTableData(undefined,{ refreshByHook: true });
      },2000);
    }
  }
});

onBeforeUnmount(() => {
  clearRefreshWebhookEvents("custom-table-" + component.uid.toString());
})
</script>

<style lang="scss" scoped>
.action-button {
  margin: 0.3rem;
}

.data-not-found-container {
  background-color: rgba($color: red, $alpha: 0.1);
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-weight: 600;
}

.custom-table-container {
  max-width: 100%;
  width: 100%;
  overflow: hidden;

  &>table {
    min-width: 100%;
  }

  &>div {
    display: flex;
  }
}

.table-header-actions {
  display: flex;
  max-width: 100%;
  justify-content: space-between;

  .actions-container {
    display: inline-flex;

    .action-button {
      :deep(button) {
        height: max-content;
        padding: .5rem;
      }
    }

    :deep(button),
    :deep(.sort-options) {
      padding: .2rem .3rem;
      flex: 1 1;
      flex-wrap: wrap;

      i {
        font-size: 1rem;
      }
    }
  }

  .search-form {
    &:deep(form) {
      display: flex;
      align-items: center;

      .form-body-container {
        flex-direction: row;
      }
    }
  }
}

.displayReverse {
  &>div>div {
    margin-top: auto;
  }
}

.mini-list {
  table {
    tbody {
      display: flex;
      flex-wrap: wrap;

      tr {
        display: inline-flex;
        flex: 1 0 auto;

        &::v-deep(.avatar-data-container) {
          margin: 0;
          padding: 0.1rem;
        }
      }

    }
  }
}

table {
  border-collapse: collapse;
  padding: 0.5rem 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  table-layout: fixed;
  overflow-wrap: break-word;

  th,
  td {
    padding: 0.2rem 0.4rem;

    &:last-child {
      white-space: break-spaces;
    }
  }

  .actionButton-header-container,
  .actionButton-data-container {
    white-space: nowrap;
    width: 1%;
  }

  thead tr {
    color: #ffffff;
    text-align: left;
  }

  tbody {
    tr {
      border-bottom: 1px solid #dddddd;

      &:nth-of-type(even) {
        background-color: #f3f3f3;
      }

      &:last-of-type {
        border-bottom: 0;
      }

      &.active-row {
        font-weight: bold;
        color: #009879;
      }

      td.data-not-found-col {
        width: 100%;
        padding: 0.3rem 0.5rem;
      }
    }
  }
}
</style>