import axios from "axios";
import config from "../config";
import { store } from "@/store";
import moment from "moment";
import i18nService from "../controllers/i18nService";
import { localStorageService } from "../controllers/localStorageService";

const baseInstance = axios.create({
  baseURL: config.backendUrl,
});

const remotoReqInlineCache = {};

export function getCachedKeys() {
  return Object.keys(remotoReqInlineCache);
}

async function setOrCacheRequest(key, req) {
  if (key == null) {
    return req();
  }
  if (
    remotoReqInlineCache[key] == null ||
    remotoReqInlineCache[key].data == null ||
    remotoReqInlineCache[key].date == null ||
    moment(remotoReqInlineCache[key].date).add(10, "m").isBefore(new Date())
  ) {
    remotoReqInlineCache[key] = {
      data: Promise.resolve(req()).catch((e) => {
        remotoReqInlineCache[key] = undefined;
      }),
      date: new Date(),
    };
  }
  return remotoReqInlineCache[key].data;
}

export function clearCacheByUrlPart(part) {
  const removeObjs = Object.keys(remotoReqInlineCache).filter(
    (key) => key.match(part) != null
  );

  return removeObjs.forEach((key) => {
    delete remotoReqInlineCache[key];
  });
}

export default {
  baseRequest: (options, shortCache = true) => {
    if (options && typeof options === "object") {
      if (options.params && typeof options.params === "object") {
        Object.entries(options.params).forEach(([key, val]) => {
          if (val && Array.isArray(val)) {
            options.params[key] = val.length > 0 ? val : [""];
            if (val.filter && !Array.isArray(val.filter)) {
              if (Object.keys(val.filter).length == 0) {
                delete options.params.filter;
              }
            }
          }

          if (val == null) {
            delete options.params[key];
          } else if (key == "force" && val == false) {
            delete options.params[key];
          }
        });
      }
    }
    options.method = options.method ?? "get";
    if (options.method != "get") {
      shortCache = false;
    }

    options.token = options.token ?? localStorageService.token;

    const reqFn = () =>
      baseInstance
        .request({
          url: options.url,
          method: options.method ?? "get",
          responseType: options.responseType ?? "json",
          params: options.params,
          data: options.data,
          withCredentials: options.withCredentials ?? true,
          headers: {
            Authorization: options.token ? `Bearer ${options.token}` : "",
            ...(options.headers ?? {}),
          },
          timeout: 30000,
        })
        .then((d) => {
          if (d.status >= 100 && d.status < 400) {
            return d.data;
          } else {
            throw d.data;
          }
        })
        .catch((e) => {
          if (e.code == "ERR_NETWORK") {
            store.commit("updateBackendStatus", false);
          } else {
            if (options?.notifyStatus != false) {
              const title = e?.response?.data?.msg ?? "warning";
              const errorDetails = e?.response?.data?.details ?? {};
              let text = JSON.stringify(
                typeof errorDetails == "object" &&
                  Object.keys(errorDetails).length == 0
                  ? ""
                  : errorDetails
              );

              if (
                e?.response?.data?.msg == "NOT_FOUND" &&
                e?.response?.data?.details?.controller
              ) {
                if (
                  i18nService.i18n.global.te(
                    "messages.controller_" +
                      e?.response?.data?.details?.controller
                  )
                ) {
                  text = i18nService.i18n.global.t(
                    "messages.controller_" +
                      e?.response?.data?.details?.controller
                  );
                } else {
                  text = e?.response?.data?.details?.controller;
                }
              }

              const NotDisplayDetails = ["NOT_AVAILABLE_NOW_MESSAGE_THREAD"];
              store.dispatch("showNotification", {
                type: "warning",
                title: i18nService.i18n.global.te("messages." + title)
                  ? i18nService.i18n.global.t("messages." + title)
                  : title,
                text: NotDisplayDetails.find((y) => y == title) ? "" : text,
              });
            }
          }
          throw e?.response?.data ?? e?.response ?? e;
        });

    const key = JSON.stringify(options);
    return shortCache ? setOrCacheRequest(key, reqFn) : reqFn();
  },
};
