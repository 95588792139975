<template>
    <div>
        <div v-if="showButton" class="open-button-container" @click.stop="(e) => showPopup(e)">
            <slot name="buttonContainer">
                <CustomButton buttonClass="success-button" :buttonText="buttonText ?? $i18n.t('general.show')">
                </CustomButton>
            </slot>
        </div>
        <div class="custom-popup-shadow" v-if="popupActive" @click.stop="() => closePopup(false)">
            <div class="custom-popup-container" :style="{ 'z-index': zIndex + 101 }" @click.stop="() => { }">
                <header class="custom-popup--header">
                    <div class="custom-popup--title">
                        <h3>{{ title ?? $i18n.t('general.update') }}</h3>
                    </div>
                    <i class="custom-popup--close custom-popup--close--icon material-icons null custom-popup--close custom-popup--close--icon custom-icon notranslate icon-scale custom-popup--close custom-popup--close--icon"
                        style="background: rgb(255, 255, 255);" @click.stop="() => closePopup(true)">
                        close
                    </i>
                </header>
                <div class="custom-popup--content">
                    <slot v-if="popupActive"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomPopupComponent",
    components: {},
    props: {
        persistent: { type: Boolean,default: () => true },
        defaultOpen: { type: Boolean,default: () => false },
        showButton: { type: Boolean,default: () => true },
        buttonText: { type: String },
        title: { type: String },
    },
    data() {
        return {
            popupActive: false,
            zIndex: 0,
        };
    },
    mounted() {
        this.popupActive = this.defaultOpen;
    },
    watch: {
        popupActive(val) {
            if (val == false) {
                this.$emit("modalClosed");
            }
            else if (val == true) {
                this.$emit("modalOpen");
            }
        },
    },
    emits: ["modalClosed","modalOpen"],
    computed: {},
    methods: {
        togglePopup() {
            this.popupActive = !this.popupActive;
        },
        showPopup() {
            this.popupActive = true;
            this.zIndex = Math.max(...Array.from(document.querySelectorAll(".con-vs-popup:not([style*=\"display: none\"])"),(el) => parseFloat(window.getComputedStyle(el).zIndex)).filter((zIndex) => !Number.isNaN(zIndex)),20001);
        },
        closePopup(force) {
            if (force) {
                this.popupActive = false;
                return;
            }
            if (this.persistent != true) {
                this.popupActive = false;
                return;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.open-button-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.custom-popup-shadow {
    z-index: 10000;
    position: fixed;
    background-color: rgba($color: black, $alpha: .3);
    top: 0;
    left: 0;
    display: flex;

    width: 100vw;
    height: 100vh;

    flex: 0 0 auto;

    justify-content: center;
    align-items: center;

    .custom-popup-container {
        transition: all .2s;
        width: 600px;
        margin: 10px;
        max-width: calc(100% - 30px);
        max-height: calc(100% - 30px);
        border-radius: 6px;
        -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
        background: #fff;
        -webkit-animation: rebound .3s;
        animation: rebound .3s;

        .custom-popup--header {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            background: rgba(0, 0, 0, .05);
            border-radius: 5px 5px;
            position: relative;

            .custom-popup--title {
                width: 100%;
                position: relative;
                overflow: hidden;
                border-radius: 5px 5px 0 0;

                h3 {
                    -webkit-transition: all .23s ease .1s;
                    transition: all .23s ease .1s;
                    padding: 8px;
                    padding-left: 12px;
                    font-size: 1.2rem;
                }
            }

            .custom-popup--close {
                transform: translate(8px, -8px);
                -webkit-transition: all .23s ease .1s;
                transition: all .23s ease .1s;
                position: relative;
                padding: 8px;
                cursor: pointer;
                -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
                border-radius: 5px;
                background: #fff;
                font-size: 1.25em;
                color: rgba(0, 0, 0, .6);
            }
        }

        .custom-popup--content {
            padding: 13px;
            font-size: .9em;
            -webkit-transition: all .23s ease .1s;
            transition: all .23s ease .1s;
            overflow: auto;
            max-height: calc(100vh - 100px);
            margin-top: 5px;
            margin-bottom: 5px;
            width: calc(100% - 5px);
        }
    }
}
</style>