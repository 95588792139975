import Vuex from "vuex";
import axios from "../axios";

import pageModule from "./modules/fb/fbPage";
import postModule from "./modules/fb/fbPost";
import tokenModule from "./modules/fb/accessToken";
import fbUserModule from "./modules/fb/fbUser";
import applicationModule from "./modules/fb/fbApplication";
import conversationModule from "./modules/fb/fbConversation";
import messageModule from "./modules/fb/fbMessage";
import commentModule from "./modules/fb/fbComment";
import fbLabelModule from "./modules/fb/fbLabel";
import fbReactionModule from "./modules/fb/fbReaction";

import igUserModule from "./modules/ig/igUser";
import igMediaModule from "./modules/ig/igMedia";
import igTaggedMediaModule from "./modules/ig/igTaggedMedia";

import igCommentModule from "./modules/ig/igComment";
import igConversationModule from "./modules/ig/igConversation";
import igCustomerUserModule from "./modules/ig/igCustomerUser";
import igMessageModule from "./modules/ig/igMessage";

import waBAModule from "./modules/wa/fbWaBAs";
import waPhoneModule from "./modules/wa/fbWaPhone";
import waContactModule from "./modules/wa/fbWaContact";
import waConversation from "./modules/wa/fbWaConversation";
import waMessageTemplateModule from "./modules/wa/fbWaMessageTemplate";
import waMessageModule from "./modules/wa/fbWaMessage";
import waMediaModule from "./modules/wa/fbWaMedia";

import waWebApplicationModule from "./modules/waWeb/waWebApplication";
import waWebSessionModule from "./modules/waWeb/waWebSession";
import waWebContactModule from "./modules/waWeb/waWebContact";
import waWebMessageModule from "./modules/waWeb/waWebMessage";
import waWebPhoneModule from "./modules/waWeb/waWebPhone";
import waWebChatModule from "./modules/waWeb/waWebChat";
import waWebLabelModule from "./modules/waWeb/waWebLabel";

import organisationModule from "./modules/organisation";
import organisationQuickReplyModule from "./modules/organisationQuickReply";
import organisationMembershipModule from "./modules/organisationMembership";

import authModule from "./modules/auth";
import notificationModule from "./modules/notification";
import loggerModule from "./modules/logger";
import defaultsModule from "./modules/defaults";

import userModule from "./modules/user";
import customerModule from "./modules/customer";
import channelModule from "./modules/channel";
import socialContentLabelModule from "./modules/socialContentLabel";
import socialContentLabelOptionModule from "./modules/socialContentLabelOption";

export const store = new Vuex.Store({
  state: {
    singlePage: false,
    appScrolled: false,
    authChecked: false,
    indexDbInit: false,
    i18nInit: false,
    backendStatus: true,
    lastUpdateNotification: undefined,
    notificationData: [],
  },
  mutations: {
    appAuthChecked(state) {
      state.authChecked = true;
    },
    appIndexDbChecked(state) {
      state.indexDbInit = true;
    },
    appi18nChecked(state) {
      state.i18nInit = true;
    },
    updateBackendStatus(state, val) {
      state.backendStatus = val;
    },
    updateSinglePage(state, val) {
      state.singlePage = val;
    },
    showNotification(state, val) {
      state.notificationData.push({
        title: val?.title ?? "Primary",
        text: val?.text ?? "Notification",
        type: val?.type ?? "primary",
      });
    },
    removeNotification(state, val) {
      const index = state.notificationData.indexOf(val);
      if (index > -1) {
        state.notificationData.splice(index, 1);
      }
    },
    updateLastUpdateNotification(state, val) {
      if (val && val.objectType && val.data) {
        if (val.objectType == state.lastUpdateNotification?.objectType) {
          state.lastUpdateNotification = undefined;
        }
        state.lastUpdateNotification = val;
      }
    },
  },
  getters: {
    authUser: (store) => store.auth.user,
    isSuperAdmin: (store) => store.auth?.user?.role == "Superadmin",
    isAdmin: (store) => store.auth?.user?.role == "Admin",
    authUserStat: (store) => store.auth?.stats,
    authToken: (store) => store.auth.token,
    appIsReady: (store) =>
      store.authChecked && store.indexDbInit && store.i18nInit,
  },
  actions: {
    updateAppScroll({state,commit}){
      if(state.appScroll != true){
        commit("appScrolled", true);
        setTimeout(()=>{
          commit("appScrolled", false);
        },200);
      } 
    },
    getOrganisationRole({}, { organisationId }) {
      return this.getters.authUserStat;
    },
    setSinglePage({ commit }, val) {
      commit("updateSinglePage", val);
    },
    objectUpdated({ commit }, val) {
      commit("updateLastUpdateNotification", val);
    },
    showNotification({ commit }, { type = "info", title, text }) {
      if (title == null) {
        switch (type) {
          case "info":
            title = "Info";
            break;
          case "warning":
            title = "Warning";
            break;
          case "danger":
            title = "Danger";
            break;
          case "success":
            title = "Success";
            break;
          default:
            title = "Notification";
            break;
        }
      }

      commit("showNotification", {
        title,
        text,
        type,
      });
    },
    notificationDisplayed({ commit }, data) {
      commit("removeNotification", data);
    },
    checkBackendConnection({ commit }) {
      return axios
        .baseRequest({
          url: "",
        })
        .then(() => {
          commit("updateBackendStatus", true);
        })
        .catch(() => {
          commit("updateBackendStatus", false);
        });
    },
  },
  modules: {
    logger: loggerModule,
    defaults: defaultsModule,

    auth: authModule,
    organisation: organisationModule,
    organisationQuickReply: organisationQuickReplyModule,
    organisationMembership: organisationMembershipModule,

    notification: notificationModule,
    user: userModule,
    customer: customerModule,
    channel: channelModule,

    socialContentLabel: socialContentLabelModule,
    socialContentLabelOption: socialContentLabelOptionModule,

    fbAccessToken: tokenModule,
    fbApplication: applicationModule,

    fbPage: pageModule,
    fbPost: postModule,
    fbConversation: conversationModule,
    fbMessage: messageModule,
    fbComment: commentModule,
    fbUser: fbUserModule,
    fbLabel: fbLabelModule,
    fbReaction: fbReactionModule,

    igUser: igUserModule,
    igMedia: igMediaModule,
    igTaggedMedia: igTaggedMediaModule,
    igComment: igCommentModule,
    igConversation: igConversationModule,
    igCustomerUser: igCustomerUserModule,
    igMessage: igMessageModule,

    fbWaBA: waBAModule,
    fbWaPhone: waPhoneModule,
    fbWaContact: waContactModule,
    fbWaConversation: waConversation,
    fbWaMessage: waMessageModule,
    fbWaMedia: waMediaModule,
    fbWaMessageTemplate: waMessageTemplateModule,

    waWebApplication: waWebApplicationModule,
    waWebSession: waWebSessionModule,
    waWebContact: waWebContactModule,
    waWebMessage: waWebMessageModule,
    waWebPhone: waWebPhoneModule,
    waWebChat: waWebChatModule,
    waWebLabel: waWebLabelModule,
  },
});
