<template>
  <div v-if="mode == 'simple' && post != null">
    <div class="post-message">
      {{ (post?.message?.length ?? 0) > 0 ? post.message : "Gorsel" }}
    </div>
    <div class="page-name">{{ page.name }}</div>
  </div>
  <CustomAvatar v-else-if="displaySimple == false" class="fb-post-avatar" :class="{
    'clickable-avatar': navigateOnClick,
    'deleted-avatar': post?.isRemoteDeleted,
    'not-read': lastComment?.isRead != true,
  }" :readyToDisplay="post != null" :dataIdentifier="postId" :displaySmall="displaySmall" :showDetails="showDetails"
    :hasLink="post?.data?.permalink_url != null" :showLink="showLink" :showInCard="showInCard" :showImage="false"
    :showActionMenu="showActionMenu" :actionMenuOptions="menuOptions" @linkClick="navigateToExternal"
    @actionMenuClick="clickMenuAction" @itemClick="navigateToPost" @refreshClick="getPost(true)">
    <template v-slot:mainData>
      <div class="post-main-inline">
        <div class="post-main-container" v-if="displaySmall != true">
          <div class="post-page-container">
            <FbPageAvatar :pageId="post.fbPageId" :organisationId="organisationId"></FbPageAvatar>
          </div>
          <div class="post-user-container" v-if="post.isMentioned && post.fromId">
            <FbUserAvatar :fbUserId="post.fromId" :displaySmall="true" :showDetails="false"></FbUserAvatar>
          </div>
          <div class="owner-agent-container" v-if="post.lastAgentId">
            <UserAvatar :userId="post.lastAgentId" :displaySmall="true"></UserAvatar>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:dataDetails>
      <div class="post-data-details-container">
        <div>
          <div class="post-content">
            <img v-if="post.photoUrl" :src="post.photoUrl" :alt="post.fbId" />
            <div class="post-message-content" :class="{
              'only-message-post-content':
                post.photoUrl == null || post.photoUrl.length == 0,
            }">
              {{ post.story ?? post.message }}
            </div>
          </div>
        </div>
        <div class="post-dates-container">
          <div>
            <label>{{ $t("model.basic-created_time") }}</label>
            <div>{{ this.formatDate(post.createdTime) }}</div>
          </div>
          <div>
            <label>{{ $t("model.basic-updated_time") }}</label>
            <div>{{ this.formatDate(post.commentsLastCheck) }}</div>
          </div>
        </div>
        <div v-if="lastComment && showLastComment" class="last-comment">
          <FbUserAvatar v-if="post.lastCommentFbUserId" :fbUserId="post.lastCommentFbUserId" :displaySmall="true"
            :showDetails="false"></FbUserAvatar>
          <div class="last-comment-sum-container">
            {{ lastComment.message }}
          </div>
          <div class="last-comment-date-container">
            <i class="fa-regular fa-calendar"></i>
            {{ formatDate(lastComment.createdTime) }}
          </div>
        </div>
      </div>
    </template>
    <template v-slot:additionalButtons>
      <FbMessageSubmit ref="submitMesssage" :postId="postId" @submited="messageSubmitted"></FbMessageSubmit>
      <FbCommentSubmit ref="submitCommit" :postId="postId" @submited="commitSubmitted"></FbCommentSubmit>
    </template>
    <template v-slot:popupData>
      <div v-html="getObjectDefaultDisplay()"></div>
    </template>
  </CustomAvatar>
  <FbPostSimpleListAvatar v-else :showPageImage="showPageImage" :post="post" :showPageInfo="showPageInfo">
  </FbPostSimpleListAvatar>
</template>

<script>
import {
  displayObjectData,
  formatDate,
  generateFacebookLinkUrl,
  manageFormSubmitButtons,
} from "@/helpers/common";
import FbPageAvatar from "@/components/facebook/FbPageAvatar";
import FbUserAvatar from "@/components/facebook/FbUserAvatar";
import UserAvatar from "@/components/data/UserAvatar";
import FbCommentSubmit from "@/components/facebook/FbCommentSubmit";
import FbPostSimpleListAvatar from "@/components/SimplifiedAction/FbPostSimpleListAvatar.vue";
import FbMessageSubmit from "./FbMessageSubmit.vue";

export default {
  name: "FbPostAvatar",
  props: {
    organisationId: { type: String },
    pageId: { type: String },
    postId: { type: String },
    navigateOnClick: { type: Boolean,default: () => false },
    showLink: { type: Boolean,default: () => false },
    showLastComment: { type: Boolean,default: () => false },
    showDetails: { type: Boolean,default: () => false },
    showActionMenu: { type: Boolean,default: () => false },
    showInCard: { type: Boolean,default: () => true },
    displaySmall: { type: Boolean,default: () => false },
    showStatus: { type: Boolean,default: () => true },
    mode: { type: String,default: () => "" },
    displaySimple: { type: Boolean,default: () => false },
    showPageInfo: { type: Boolean,default: () => true },
    showPageImage: { type: Boolean,default: () => false },
  },
  data() {
    return {
      post: undefined,
      lastComment: undefined,
      page: undefined,
      menuOptions: [],
    };
  },
  components: {
    FbPageAvatar,
    FbUserAvatar,
    UserAvatar,
    FbCommentSubmit,
    FbPostSimpleListAvatar,
    FbMessageSubmit,
  },
  watch: {
    postId() {
      this.getPost();
    },
    "$store.state.lastUpdateNotification"(val) {
      if (
        val &&
        this.post &&
        val.objectType == "FBPOST_UPDATED" &&
        val.data &&
        Array.isArray(val.data) &&
        val.data.find((x) => x._id == this.post._id)
      ) {
        this.getPost();
      }
    },
  },
  mounted() {
    this.getPost();
  },
  methods: {
    messageSubmitted() {
      this.getPost(true);
      this.$emit("avatarUpdated",{ action: "messageSubmitted" });
    },
    updateMenuList() {
      this.menuOptions = [
        { text: this.$i18n?.t("general.markAsRead"),key: "markAsRead" },
        {
          text: this.$i18n?.t("general.markAllRead"),
          key: "markAllCommentsRead",
        },
        { text: this.$i18n?.t("general.submitComment"),key: "submitComment" },
      ];
    },
    clickMenuAction(val) {
      switch (val?.val) {
        case "markAsRead":
          this.markAsRead(val?.event);
          break;
        case "submitComment":
          if (this.$refs.submitCommit) {
            const comment = Array.isArray(this.$refs.submitCommit)
              ? this.$refs.submitCommit[0]
              : this.$refs.submitCommit;
            comment.showPopup();
          }
          break;
        case "privateMessage":
          if (this.$refs.submitMesssage) {
            const message = Array.isArray(this.$refs.submitMesssage)
              ? this.$refs.submitMesssage[0]
              : this.$refs.submitMesssage;
            message.showPopup();
          }
          break;
      }
    },
    markAsRead(e) {
      manageFormSubmitButtons(e,() => {
        return this.$store
          .dispatch("fbPost/markAsRead",{ id: this.postId })
          .then(() => {
            this.$refs?.avatar?.getConversation();
            this.getPost(true);
            this.$emit("avatarUpdated",{ action: "markAsRead" });
          });
      });
    },
    commitSubmitted() {
      this.getPost(true);
      this.$emit("avatarUpdated",{ action: "commitSubmitted" });
    },
    navigateToExternal() {
      if (this.post?.data?.permalink_url) {
        return window.open(
          generateFacebookLinkUrl(this.post.data.permalink_url),
          "_blank"
        );
      }
    },
    formatDate(val) {
      return formatDate(val);
    },
    navigateToPost() {
      if (this.navigateOnClick == false) {
        return;
      }
      const query = {};
      if (this.organisationId) {
        query.organisationId = this.organisationId;
      }

      this.$router.push({
        name: "pagePostDetails",
        params: { id: this.post?.fbPageId,postId: this.postId },
        query,
      });
    },
    getPost(force = false) {
      if (this.postId) {
        return this.$store
          .dispatch("fbPost/getById",{
            organisationId: this.organisationId,
            id: this.postId,
            pageId: this.pageId,
            force,mergeRequest: true
          })
          .then((d) => {
            this.post = d;
            if (d.fbPageId) {
              this.$store
                .dispatch("fbPage/getById",{
                  id: d.fbPageId,mergeRequest: true
                })
                .then((s) => {
                  this.page = s;
                });
            }
            if (d.lastCommentId) {
              this.$store
                .dispatch("fbComment/getById",{
                  id: d.lastCommentId,
                  mergeRequest: true
                })
                .then((c) => {
                  this.lastComment = c;
                });
            }
            this.updateMenuList();
          });
      }
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.post.data ?? {});
    },
  },
};
</script>

<style lang="scss" scoped>
.fb-post-avatar {
  &.deleted-avatar {
    opacity: 0.5;
  }

  .post-main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    .post-user-container {
      margin-left: 0.5rem;
      padding: 0.5rem;
      font-size: 0.8rem;
      background-color: rgba($color: gray, $alpha: 0.3);
      border-radius: 0.5rem;
    }
  }

  .post-data-details-container {
    display: flex;
    flex-direction: column;
  }

  .participants-container {
    display: flex;

    &>div {
      display: flex;
    }
  }

  .post-dates-container {
    display: flex;

    flex-direction: row;
    justify-content: center;

    color: gray;

    &>* {
      label {
        font-size: 0.7rem;
      }

      margin: 0.3rem;
      font-size: 0.8rem;
    }
  }

  .post-content {
    font-size: 1.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.2rem;

    img {
      max-width: 25rem;
      max-height: 25rem;
      border-radius: 0.5rem;
      overflow: hidden;
      margin: 0.2rem;
    }

    .post-message-content {
      font-size: 0.8rem;
      line-height: 1rem;
      max-width: 30rem;
      word-break: break-all;
      margin-top: 0.5rem;

      &.only-message-post-content {
        font-size: 1rem;
      }
    }
  }

  .last-comment {
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    padding: 0.5rem;
    margin: 0.5rem;
    display: inline-block;
    margin-left: auto;
    max-width: 25rem;

    .last-comment-sum-container {
      padding: 0.3rem 0.5rem;
    }

    .last-comment-date-container {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      font-size: 0.7rem;
      font-weight: 400;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    &::v-deep(.vs-button) {
      .fa-brands {
        font-size: 1.2rem;
      }
    }
  }

  .post-avatar-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5rem;
    cursor: pointer;

    .post-name-container {
      font-size: 1rem;
      font-weight: 500;
    }

    .post-details-container {
      font-size: 0.8rem;
      color: gray;
    }
  }

  &>* {
    padding: 0 0.2rem;
  }

  &.unknown-post-avatar {
    opacity: 0.9;
  }

  .post-name-container {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .post-main-inline {
    display: flex;
    align-items: center;
  }

  .post-status {
    margin: 1rem;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: rgba($color: lightgreen, $alpha: 0.5);

    &.not-read {
      background-color: rgba($color: darkred, $alpha: 0.5);
    }
  }
}
</style>