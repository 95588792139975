import axios from "@/axios";
import { checkIdExist,storeDefaultModule } from "../common";

const module = storeDefaultModule('/api/organisations',{
  actions: {
    getByFbPages({ dispatch },{ fbPageId,params = {} }) {
      checkIdExist(fbPageId,{ dispatch });
      const reqOptions = params.options;
      delete params.options;
      return axios
        .baseRequest({
          url: `/api/organisations/byFbPages/${fbPageId}`,
          params: {
            ...params,
            ...reqOptions,
          },
        },
          !(reqOptions?.force == true))
        .then((d) => {
          return d.data;
        });
    },
    getByWaBAIds({ dispatch },{ fbWaBAId,params = {} }) {
      checkIdExist(fbWaBAId,{ dispatch });
      const reqOptions = params.options;
      delete params.options;
      return axios
        .baseRequest({
          url: `/api/organisations/byWaBAs/${fbWaBAId}`,
          params: {
            ...params,
            ...reqOptions,
          },
        },
          !(reqOptions?.force == true))
        .then((d) => {
          return d.data;
        });
    },
    canManage({ rootState },id) {
      if (rootState?.auth?.stats?.canManage == true) {
        return true
      }
      if (id == null) {
        return false;
      }
      return rootState?.auth?.stats?.manageOrganisationIds?.find(x => x == id) != null;
    },

  },
  cacheKey: "ORGANISATION"
});

const clearCachedDataSuper = module.actions.clearCachedData;
module.actions.clearCachedData = ({ dispatch },{ item,key }) => {
  return clearCachedDataSuper({ dispatch },{ item,key });
}

export default module;