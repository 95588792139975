<template>
  <div @click.stop="displayDialog(true)">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ConfirmationDialogCmp",
  components: {},
  props: {
    id: { type: String, default: () => "" },
    dialogType: { type: String, default: () => "confirm" },
    dialogColor: { type: String, default: () => "danger" },
    dialogTitle: {  type: String,default: () => undefined },
    dialogText: {   type: String,default: () => undefined },
    displayInitial: { type: Boolean,default: () => false },
  },
  watch: {},
  data() {
    return {
      dialog: undefined,
    };
  },
  mounted() {
    if (this.displayInitial) {
      this.displayDialog();
    }
  },
  methods: {
    displayDialog(fromButton = false) {
      if (fromButton) {
        if (this.displayInitial == true) {
          return;
        }
      }
      this.dialog = this.$vs.dialog({
        type: this.dialogType,
        color: this.dialogColor,
        title: this.dialogTitle ??  this.$i18n?.t('general.delete'),
        text: this.dialogText ?? this.$i18n?.t('general.deleteConfirmationText'),
        accept: () => this.$emit("confirmed", this.id),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>