<template>
  <CustomAvatar class="notification-avatar" :class="{
    'clickable-avatar': navigateOnClick,
    'unread-notification': notification?.isRead != true,
  }" :readyToDisplay="notification != null" :dataIdentifier="notification?._id" :showImage="showImage"
    :displaySmall="displaySmall" :showDetails="showDetails" :showActionMenu="showActionMenu"
    :actionMenuOptions="menuOptions" @itemClick="navigateToApp" @actionMenuClick="clickMenuAction"
    @refreshClick="getNotification(true)">
    <template v-slot:defaultImage>
      <div v-if="notification">
        <i v-if="notification.type == 'NEW_FB_MESSAGE' ||
          notification.type == 'REACTION_FB_MESSAGE'
          " class="fa-solid fa-message"></i>
        <i v-else-if="notification.type == 'NEW_FB_COMMENT'" class="fa-solid fa-comment"></i>
        <i v-else class="fa-solid fa-envelope"></i>
      </div>
    </template>
    <template v-slot:mainData>
      <div>{{ notificationText }}</div>
      <div class="date-container">
        <i class="fa-regular fa-calendar"></i>
        {{ formatDate(notification._id) }}
      </div>
    </template>
    <template v-slot:dataDetails> </template>
    <template v-slot:additionalContainers> </template>
    <template v-slot:popupData>
      <div v-if="notification.type == 'NEW_FB_COMMENT' ||
        notification.type == 'NEW_FB_POST_REACTION' ||
        notification.type == 'NEW_FB_MESSAGE' ||
        notification.type == 'REACTION_FB_MESSAGE'
        ">
        <CustomButton buttonClass="success-button" buttonText="general.showDetails" @click.stop="navigateToDetails">
        </CustomButton>
        <CustomButton buttonClass="success-button" buttonText="general.showApp" @click.stop="navigateToApp">
        </CustomButton>
      </div>
      <div v-if="notification.type == 'NEW_FB_COMMENT'">
        <FbCommentAvatar :commentId="notificationData._id" :postId="notificationData?.fbPostId"
          :pageId="notificationData?.fbPageId" :displaySmall="true" :showInCard="false" :showAddInfo="false"
          :showPost="true" :showPage="true"></FbCommentAvatar>
      </div>
      <div v-else-if="notification.type == 'NEW_FB_POST_REACTION'">
        <FbPostAvatar v-if="showPost" :pageId="notificationData.fbPageId" :postId="notificationData._id">
        </FbPostAvatar>
      </div>
      <div v-else-if="notification.type == 'NEW_FB_MESSAGE' ||
        notification.type == 'REACTION_FB_MESSAGE'
        ">
        <FbMessageAvatar :messageId="notificationData._id" :displaySimple="true"></FbMessageAvatar>
      </div>
      <div v-else>{{ notificationData ?? notification }}</div>
    </template>
  </CustomAvatar>
</template>

<script>
import FbMessageAvatar from "@/components/facebook/FbMessageAvatar";
import FbPostAvatar from "@/components/facebook/FbPostAvatar";
import { formatDate } from "@/helpers/common";

export default {
  name: "NotificationAvatar",
  props: {
    notificationId: { type: String },
    navigateOnClick: { type: Boolean,default: () => false },
    showImage: { type: Boolean,default: () => true },
    showDetails: { type: Boolean,default: () => true },
    displaySmall: { type: Boolean,default: () => false },
    showActionMenu: { type: Boolean,default: () => false },
  },
  components: { FbMessageAvatar,FbPostAvatar },
  data() {
    return {
      notification: undefined,
      notificationData: undefined,
      menuOptions: [],
      notificationText: "-",
    };
  },
  watch: {
    notificationId() {
      this.getNotification();
    },
    "$store.state.lastUpdateNotification"(val) {
      if (
        val &&
        val.objectType &&
        val.data &&
        val.objectType.match(/^NOTIFICATION/)
      ) {
        if (
          val.objectType.match(/^NOTIFICATION_UPDATED/) &&
          this.notificationId &&
          Array.isArray(val.data) &&
          val.data.find((x) => x._id == this.notificationId)
        ) {
          this.getNotification();
        }
        if (
          val.objectType.match(/^NOTIFICATION_MULTIPLE_UPDATED/) &&
          val.data &&
          this.notificationId &&
          Array.isArray(val.data) &&
          val.data.find(
            (x) => x.items?.find((y) => y._id == this.notificationId) != null
          )
        ) {
          this.getNotification();
        }
      }
    },
  },
  mounted() {
    this.getNotification();
  },
  methods: {
    async showMessage() {
      let username = "-";
      if (
        this.notificationData &&
        (this.notification.type == "NEW_IG_MESSAGE" ||
          this.notification.type == "NEW_IG_COMMENT" ||
          this.notification.type == "READ_IG_MESSAGE")
      ) {

        if (this.notificationData.fromId == null) {
          username = "-"
        } else {
          username = await this.$store
            .dispatch("igCustomerUser/getById",{
              id: this.notificationData.fromId,
              mergeRequest: true
            })
            .then((d) => d.username)
            .catch(() =>
              this.$store
                .dispatch("igUser/getById",{
                  id: this.notificationData.fromId,
                  mergeRequest: true
                })
                .then((d) => d.username)
                .catch(() => "-")
            );
        }

      } else if (this.notificationData?.fromId) {
        username = await this.$store
          .dispatch("fbUser/getById",{
            id: this.notificationData.fromId,
            mergeRequest: true
          })
          .then((d) => d.name)
          .catch(() => "-");
      }
      let pageName = "-";
      if (this.notificationData?.fbPageId) {
        pageName = await this.$store
          .dispatch("fbPage/getById",{
            id: this.notificationData.fbPageId,
            mergeRequest: true
          })
          .then((d) => d.name)
          .catch(() => "-");
      }

      if (this.notification.type == "NEW_FB_POST_REACTION") {
        if (this.notificationData?.fbUserId) {
          username = await this.$store
            .dispatch("fbUser/getById",{
              id: this.notificationData?.fbUserId,
              mergeRequest: true
            })
            .then((d) => d.name)
            .catch(() => "-");
        }
        if (this.notificationData?.post) {
          pageName = await this.$store
            .dispatch("fbPage/getById",{
              id: this.notificationData.fbPageId,
              mergeRequest: true
            })
            .then((d) => d.name)
            .catch(() => "-");
        }
      }

      let notificationFormat = [];

      switch (this.notification.type) {
        case "NEW_IG_COMMENT":
        case "NEW_IG_MESSAGE":
        case "READ_IG_MESSAGE":
        case "NEW_FB_POST":
        case "NEW_FB_POST_REACTION":
        case "NEW_FB_COMMENT":
        case "NEW_FB_MESSAGE":
        case "REACTION_FB_MESSAGE":
        case "READ_FB_MESSAGE":
          notificationFormat = [pageName,username];
          break;
      }
      return (this.notificationText = this.$i18n?.te(
        `notification.${this.notification.type}`
      )
        ? this.$i18n?.t(`notification.${this.notification.type}`,notificationFormat)
        : this.notification.type);
    },
    navigateToDetails() {
      switch (this.notification.type) {
        case "NEW_FB_POST_REACTION":
          this.$router.push({
            name: "pagePostDetails",
            params: {
              id: this.notificationData?.targetId,
            },
          });
          break;
        case "NEW_FB_COMMENT":
          this.$router.push({
            name: "pageCommentDetails",
            params: {
              id: this.notificationData.fbPageId,
              postId: this.notificationData.fbPostId,
              commentId: this.notificationData._id,
            },
          });
          break;
        case "NEW_FB_MESSAGE":
          this.$router.push({
            name: "messageDetails",
            params: {
              id: this.notificationData.fbPageId,
              conversationId: this.notificationData.fbConversationId,
              messageId: this.notificationData._id,
            },
          });
          break;
        case "REACTION_FB_MESSAGE":
          this.$router.push({
            name: "messageDetails",
            params: {
              id: this.notificationData.fbPageId,
              conversationId: this.notificationData.fbConversationId,
              messageId: this.notificationData._id,
            },
          });
          break;
        default:
          this.$router.push({
            name: "notificationDetails",
            params: { id: this.notificationId },
          });
      }
    },
    navigateToApp(e) {
      let navigateRoute = "details";
      let itemType = "";
      let itemId = this.notificationData?._id;

      this.clickMenuAction({ val: "markAsRead" });
      switch (this.notification.type) {
        case "NEW_FB_COMMENT":
          itemType = "fbPost";
          itemId = this.notificationData?._id;
          navigateRoute = "appView";
          break;
        case "NEW_FB_POST_REACTION":
          itemType = "fbPost";
          itemId = this.notificationData?._id;
          navigateRoute = "appView";
          break;
        case "READ_IG_MESSAGE":
        case "NEW_IG_MESSAGE":
          itemType = "igConversation";
          itemId = this.notificationData?._id;
          navigateRoute = "appView";
          break;
        case "NEW_IG_COMMENT":
          itemType = "igMedia";
          itemId = this.notificationData?._id;
          navigateRoute = "appView";
          break;
        case "NEW_FB_MESSAGE":
        case "REACTION_FB_MESSAGE":
        case "READ_FB_MESSAGE":
          itemType = "fbConversation";
          itemId = this.notificationData?._id;
          navigateRoute = "appView";
          break;
        default:
          itemType = "";
      }

      let routeParams = {
        name: "notificationDetails",
        params: { id: this.notificationId },
      };

      if (navigateRoute == "appView") {
        routeParams = {
          name: "appView",
          query: {
            itemType,
            itemId,
          },
        };
      }

      if (e && e instanceof PointerEvent && e.metaKey) {
        const routeData = this.$router.resolve(routeParams);
        window.open(routeData.href,"_blank");
      } else {
        this.$router.push(routeParams);
      }
    },
    updateMenuList() {
      this.menuOptions = [
        { text: this.$i18n.t("general.markAsRead"),key: "markAsRead" },
      ].filter((x) => {
        if (x.key == "markAsRead") {
          return this.notification?.isRead != true;
        }
        return true;
      });
    },
    clickMenuAction(val) {
      if (this.notification?.isRead == true) {
        return;
      }
      switch (val?.val) {
        case "markAsRead":
          this.$store.dispatch("notification/markAsRead",{
              id: this.notificationId,
            })
            .catch(() => null);
          break;
      }
    },
    getNotification(force = false) {
      if (this.notificationId) {
        this.$store.dispatch("notification/getById",{
            id: this.notificationId,
            mergeRequest: true,
            force,
          })
          .then(async (d) => {
            this.notification = d;

            let objectId;
            let promise;

            switch (this.notification.type) {
              case "NEW_FB_POST_REACTION":
                objectId = this.notification.data._id;
                promise = this.$store.dispatch('fbReaction/getById',{
                  id: objectId,
                });
                break;
              case "NEW_FB_POST":
                objectId = this.notification.data._id;
                promise = this.$store.dispatch('fbPost/getById',{
                  id: objectId,
                });
                break;
              case "NEW_FB_COMMENT":
                objectId = this.notification.data._id;
                promise = this.$store.dispatch('fbComment/getById',{
                  id: objectId,
                });
                break;
              case "READ_FB_MESSAGE":
              case "DELIVERY_FB_MESSAGE":
                objectId = this.notification.data.fbConversationId;
                promise = this.$store.dispatch('fbConversation/getById',{
                  id: objectId,
                });
                break;
              case "NEW_FB_MESSAGE":
                objectId = this.notification.data._id;
                promise = this.$store.dispatch('fbMessage/getById',{
                  id: objectId,
                });
                break;
              case "NEW_IG_COMMENT":
                objectId = this.notification.data._id;
                promise = this.$store.dispatch('igComment/getById',{
                  id: objectId,
                });
                break;
              case "READ_IG_MESSAGE":
              case "NEW_IG_MESSAGE":
                objectId = this.notification.data._id;
                promise = this.$store.dispatch('igMessage/getById',{
                  id: objectId,
                });
                break;
              case "NEW_IG_MENTIONED_MEDIA":
                objectId = this.notification.data._id;
                promise = this.$store.dispatch('igTaggedMedia/getById',{
                  id: objectId,
                });
                break;
              default:
                this.notificationData = undefined;
                break;
            }

            if (promise) {
              await promise.then(d => {
                this.notificationData = d;
              });
            }

            this.showMessage();
            this.updateMenuList();
          })
          .catch(() => null);
      }
      return;
    },
    formatDate(val) {
      return formatDate(val,"L LT",true);
    },
  },
};
</script>

<style lang="scss" scoped>
.unread-notification {
  &::v-deep(.avatar-img-container) {
    .avatar-icon-background {
      color: red;
    }
  }
}

.notification-avatar {
  .date-container {
    font-size: .8rem;
  }

  &::v-deep(.main-data-container) {
    font-size: 0.8rem;
  }

  &.page-user {
    &::v-deep(.avatar-img-container) {
      opacity: 0.7;
    }

    &::v-deep(.main-data-container) {
      color: gray;
      font-size: 0.8rem;
    }
  }
}

.user-details-container {
  word-break: break-word;
}
</style>