import axios from "@/axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/fb/accesstokens',{
  actions: {
    getOrganisationApplicationIds(a,{ organisationId }) {
      return axios
        .baseRequest({
          url: `/api/fb/accesstokens/organisations/${organisationId}/applications/ids`,
        })
        .then((d) => d.data);
    },
    genereateLongLive({ dispatch },id) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/fb/accesstokens/${id}/longlive`,
        })
        .then((d) => {
          return d;
        });
    },
    getApplicants({ dispatch },{id}) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "GET",
          url: `/api/fb/accesstokens/${id}/applicants`,
        })
        .then((d) => {
          return d.data;
        });
    },
    postApplicants({ dispatch },{id,data}) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/fb/accesstokens/${id}/applicants`,
          data
        })
        .then((d) => {
          return d.data;
        });
    },
    
    generate(a,{ organisationId,applicationId }) {
      return axios
        .baseRequest({
          method: "POST",
          params: { organisationId,applicationId },
          url: `/api/fb/accesstokens/generate`,
          data: {
            applicationId,
          },
        })
        .then((d) => {
          return d.data.token;
        });
    },
    dublicate(a,{ id,organisationId }) {
      return axios
        .baseRequest({
          method: "POST",
          params: { organisationId },
          url: `/api/fb/accesstokens/${id}/dublicate`,
          data: {
            organisationId,
          },
        })
        .then((d) => {
          return d.data.token;
        });
    },
    token(a,data) {
      return axios
        .baseRequest({
          method: "post",
          data: data,
          url: `/api/auth/token`,
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBACCESSTOKEN"
});

export default module;